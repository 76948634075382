// @import "bootstrap-icons/font/bootstrap-icons.css";
// @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200&family=Lato:wght@100;300;400;700;900&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
// @import "./constants.scss";
// @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200&family=Lato:wght@100;300;400;700;900&family=PT+Serif:ital@1&family=Playfair+Display:ital,wght@0,400;0,500;1,400&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");

@import "./main.scss";
/********************************************* common css start ************************************************/

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   background: #dfdfdf;
// }
// body::-webkit-scrollbar {
//   display: none;
// }

// .d-none {
//   display: none;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

// @font-face {
//   font-family: leJeuneDeck;
//   src: url(../../public/static/assets/fonts/LeJeuneDeck-Regular.otf);
//   font-weight: 400;
// }

// @font-face {
//   font-family: foundersGroteskMonoMedium;
//   src: url(../../public/static/assets/fonts/FoundersGroteskMono-Medium.otf);
//   font-weight: 500;
// }

// @font-face {
//   font-family: foundersGroteskMonoRegular;
//   src: url(../../public/static/assets/fonts/FoundersGroteskMono-Regular.otf);
//   font-weight: 400;
// }

// @font-face {
//   font-family: foundersGroteskTextMedium;
//   src: url(../../public/static/assets/fonts/FoundersGroteskText-Medium.otf);
//   font-weight: 500;
// }

// @font-face {
//   font-family: foundersGroteskTextRegular;
//   src: url(../../public/static/assets/fonts/FoundersGroteskText-Regular.otf);
//   font-weight: 400;
// }
// @font-face {
//   font-family: foundersGroteskTextBold;
//   src: url(../../public/static/assets/fonts/FoundersGroteskText-bold.otf);
//   font-weight: 700;
// }

// .leJeuneDeck {
//   font-family: leJeuneDeck;
// }

// .foundersGroteskMonoRegular {
//   font-family: foundersGroteskMonoRegular;
// }

// .foundersGroteskMonoMedium {
//   font-family: foundersGroteskMonoMedium;
// }

// .foundersGroteskTextRegular {
//   font-family: foundersGroteskTextRegular;
// }

// .foundersGroteskTextMedium {
//   font-family: foundersGroteskTextMedium;
// }
// .foundersGroteskTextBold {
//   font-family: foundersGroteskTextBold;
// }

// .font_12 {
//   font-size: 12px !important;
// }
// .font_14 {
//   font-size: 14px !important;
// }
// .font_16 {
//   font-size: 16px !important;
// }
// .font_18 {
//   font-size: 18px !important;
// }
// .font_24 {
//   font-size: 24px !important;
// }

// .mb{                           // common class for margin bottom 
//   margin-bottom: 30px;
//   @media screen and (min-width: 960px) {
//     margin-bottom: 40px; 
//   }
// }

// .main-section {
//   .para-main {
//     font-family: leJeuneDeck;
//     font-weight: 400;
//     font-size: 28px;
//     line-height: 40px;
//     color: $bs-head-color;
//   }
//   .heading-main {                                             //not used
//     font-family: "Lato", sans-serif;
//     color: var(--bs-para-color);
//   }
// }
// @media screen and (max-width: 959px) {
//   .main-section {
//     .para-main {
//       font-family: leJeuneDeck;
//       font-size: 18px;
//       font-style: normal;
//       line-height: 24px;
//       color: var(--polo-primary, #041e3a);
//     }
//   }
// }

// .divider_margin {
//   margin-bottom: 2px;
// }

// /**** common tooltip ******/
// [data-tooltip] {
//   display: inline-block;
//   position: relative;
 
// }
// /* Tooltip styling */
// [data-tooltip]:before {
//   content: attr(data-tooltip);
//   display: none;
//   position: absolute;
//   background:  #212121;
//   color: #fff;
//   padding: 10px 13px 8px 12px;
//   gap: 10px;
//   font-size: 12px;
//   line-height: 22px;
//   text-align: center;
//   border-radius: 3px;
//   width: 180px;
//   font-weight: 100;
//   font-family: foundersGroteskTextRegular;
// }
// /* Dynamic horizontal centering */
// [data-tooltip-position="top"]:before
//  {
//     left: 100%;
//     -ms-transform: translateX(-50%);
//     -moz-transform: translateX(-50%);
//     -webkit-transform: translateX(-50%);
//     transform: translateX(-50%);
// }
// /* Dynamic vertical centering */
// [data-tooltip-position="top"]:before {
//   bottom: 180%;

// }
// /* Tooltip arrow styling/placement */
// [data-tooltip]:after {
//   content: '';
//   display: none;
//   position: absolute;
  
//   height: 20;
//   border-color: transparent;
//   border-style: solid;
//   margin-bottom: -5px;
// }
// /* Dynamic horizontal centering for the tooltip */
// [data-tooltip-position="top"]:after
//  {
//   left: 50%;
// }
// /* Dynamic vertical centering for the tooltip */
// [data-tooltip-position="top"]:after {
//   bottom: 180%;
//   border-width: 6px 6px 0;
//   border-top-color:#212121   ;
// }
// /* Show the tooltip when hovering */
// [data-tooltip]:hover:before,
// [data-tooltip]:hover:after {
//     display: block;
//     z-index: 50;
// }

/************ PageNotFound.js css start **********************/
// .pagenotfound {
//   padding: 98px 112px 98px 112px;
//   }
/*************** PageNotFound.js css end ********************/

/***************************BrandingIntro.js css start **********/
// .sc-main {                                   
//   margin-top: 40px;
//   margin-left: 9px;
// }
// .fadeInOut{                                            
//   animation: fadeInOuts 2s ease-in-out forwards;
// }

// @keyframes fadeInOuts {
//   0%{
//       opacity: 0;
//   }
//   100%{
//       opacity: 1;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .i-pad {
//     margin-left: -1.1rem;
//   }
// }
// @media screen and (min-width: 1400px) {
//   .i-pad {
//     margin-left: -3.1rem;
//   }
// }
/***************************BrandingIntro.js css end **********/
// //common divider animation 
// .dividerAnimate {
//   animation: fadeIn ease 3s;
//   -webkit-animation: fadeIn ease 3s;
//   -moz-animation: fadeIn ease 3s;
//   -o-animation: fadeIn ease 3s;
//   -ms-animation: fadeIn ease 3s;
// }
// .dividerAnimate1 {
//   animation: fadeIn ease 7s;
//   -webkit-animation: fadeIn ease 7s;
//   -moz-animation: fadeIn ease 7s;
//   -o-animation: fadeIn ease 7s;
//   -ms-animation: fadeIn ease 7s;
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-moz-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-o-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-ms-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .btn--animated {
//   animation: moveInButton 0.3s ease-out forwards;
// }
// @keyframes moveInButton {
//   0% {
//     opacity: 0;
//     transform: translateY(-2rem);
//   }

//   100% {
//     opacity: 1;
//     transform: translate(0);
//   }
// }

// .text1 {
//   animation: fadeInImge ease-in 1s;
// }

// .text2 {
//   animation: fadeInImge ease-in 1s;
// }


// .img1 {
//   animation: fadeInImge ease-in 3s;
// }

// .img2 {
//   animation: fadeInImge ease-in 7s;
// }
// .img3 {
//   animation: fadeInImge ease-in 10s;

// }

// @keyframes fadeInImge {
//   0% {
//     opacity: 0;
//   }
  
//   100% {
//     opacity: 1;
//   }
// }

// .chat_options {                                             
//   .chat_msg {
//     width: fit-content;
//     max-width: 800px;
//     background: #fbfbf9;
//     font-size: 18px;
//     color: var(--polo-primary, #041e3a);
//     margin-bottom: 30px;
//     border-radius: 10px;
//     padding: 12px 20px 12px 12px;
//     box-shadow: 0px 5px 10px 0px #0000000d;
//     @media screen and (max-width: 576px) {
//       width: fit-content;
//       font-size: 14px;
//       max-width: 450px;
//       line-height: 18px;
//       margin-right: 1px !important;
//     }
//     @media screen and (min-width: 960px) {
//     margin-bottom: 40px;
//     }
//     span.badge-text {
//       padding-top: 7px;
//     }
//     .user_bubble {
//       color: #6d6f73;
//       font-size: 10px;
//       line-height: normal;
//       padding: 12px;
//       border-radius: 3px;
//       background: var(--collection-warm-grey-4, #e1e1dc);
//       font-family: foundersGroteskMonoMedium;
//       margin-right: 12px;
//       margin-left: 2px;
//       @media screen and (max-width: 959px) {
//         margin-left: 0px;
//         padding: 7px;
//       }
      
//     }
//   }
//   @keyframes text_animation {
//     from {
//       transform: translateY(100%);
//     }
//     to {
//       transform: translateY(0);
//     }
//   }
// }

// .product-detail {                                      
//   font-size: 18px;
//   line-height: 32px;
//   color: #041E3A;
//   font-style: normal;
//   font-family: leJeuneDeck;
//   @media screen and (max-width: 959px) {
//     font-size: 14px;
//     line-height: 24px;
//   }
// }

/********************************************* common css end ************************************************/

.thumbs{                                          //not used 
  border-radius: 100px !important;
  background: #FFF;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 8px 16px 6px 16px;
  margin-left:20px;

  @media screen and (min-width: 768px) and (max-width: 1023px){
    margin-top: 10px;
  }
}

/*************************************** Header.js css start ************************************************/

// .header_transparent {
//   position: sticky;
//   background: transparent !important;
//   backdrop-filter: none !important;
//   box-shadow: none !important;
// }
// //desktop
// @media screen  and (min-width:960px) {
//   .icon-dist{
//     margin-right: 20px;

//   }
//   .cart-icon{
//     height:21px;
//     width: 17.9px;
//     align-items: center;
  
//   }
//   .hamburger-menu-icon{
//       height:21px;
//       width: 17.9px;
//      align-items: center;
     

//   }
 
// }
// //mobile
// @media screen  and (max-width:959px) {
//   .icon-dist{
//     margin-right:20px;

//   }
//   .cart-icon{
//     height:16px;
//     width: 16px;
//     align-items: center;
  
//   }
//   .hamburger-menu-icon{
//      height: 16px;
//      width: 16px;
//      align-items: center;
     

//   }
//   .profile-icon{
//    width: 16px;
//    height: 16px; 
//   }
  
// }

// .header {
//   font-family: leJeuneDeck;
//   top: 0;
//   position: fixed;
//   width: 100%;
//   z-index: 30;
//   background: rgba(255, 255, 255, 0.75);
//   box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
//   backdrop-filter: blur(7.5px);
//   .header_options {
//     padding: 18px 30px;
//     @media screen and (max-width:959px){
//       padding: 18px 20px; 
//     } 
//   }
//   .header_logo {
//     width: 220px;
//     height: auto;
//     img {
//       width: 254.75px;
//       height: 18px;
//       @media screen and (max-width:959px){
//       width: 165.33px;
//       height: 12px;
//       }
//     }
//     @media screen and (min-width: 280px) and (max-width:767px)
//     {
//       width: 175px ;
//     }

//   }

//   .sidebar-body {
//     background: #f5f5f2;
//   }
//   .menu-close-icon{
//     background: none;
//     border: none;
//   }
//   .usersection {
//     color: #041e3a;
//     font-size: 18px;
//     font-weight: 400;
//     .username {
//       margin-left: 14px;
//       font-size: 18px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: 24px;
//     }
//     .policy {
//       margin-top: 6px;
//       font-size: 12px;
//       font-weight: 500;
//     }
//     .userterms {
//       font-size: 10px;
//       font-weight: 400;
//       color: #6d6f73;
//       a {
//         color: #041e3a;
//       }
//     }
    
//     .faq-div{
//       margin-top: 32px;
//       color: #041E3A;
//       font-family: leJeuneDeck;
//       font-size: 18px;
//       font-style: normal;
//       line-height: 24px;
//     }
//     .signout-div{
//       margin-top: 32px;
//       margin-bottom: 10px;
//       color: #041E3A;
//       font-family: leJeuneDeck;
//       font-size: 18px;
//       font-style: normal;
//       line-height: 24px;
//       cursor: pointer;
//     }

//     @media screen and (max-width: 576px) {
//       .faq-div{
//         margin-top: 27px;
//         font-size: 14px;
//         line-height: 18px;
//       }
//       .signout-div{
//         margin-top: 27px;
//         margin-bottom: 32px;
//         font-size: 14px;
//         line-height: 18px;
//         cursor: pointer;
//       }
//       .policy {
//         margin-top: 32px;
//       }
//       .username {
//         font-size: 14px;
//         line-height: 18px;
//       }
//     }
//   }
//   .offcanvas.offcanvas-end {
//     height: 100vh;
//   }
//   @media screen and (max-width: 767px){
//     .offcanvas.offcanvas-end {
//       height: 100vh;
//       width: 100%;
//       background-clip: border-box !important;
//     }
//   }
// }
/**************************************** Header.js css end ********************************************************/



/**************************************** Layouts css start *************************************************/
// .mainlayout {
//   background: linear-gradient(180deg, #EEEEEE 0%, #DFDFDF 100%), #FFFFFF;
//   position: relative;
//   .mainroute {
//     width: 100%;
//     height: 100vh;
//   }
// }
/**************************************** Layouts css end *************************************************/


/******************************************* TextResponseStatic.js css start **************************************/
.TextResponseStatic {
  .chat_msg1 {
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: #041E3A;
    border-radius: 10px;
    padding: 12px;
    @media screen and (max-width: 959px){
      font-size: 13px !important;
      line-height: 22px !important;
    }
    .chat1 {
      font-family: leJeuneDeck;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 15px;
      color: var(--polo-primary, #041e3a);
    }
    .chat2 {
      font-family: leJeuneDeck;
      font-size: 16px;
      line-height: 30px;
      color: var(--polo-primary, #041e3a);
    }
    @media screen and (max-width: 576px) {
      .chat1 {
        // font-family: leJeuneDeck;
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .chat2 {
        font-family: leJeuneDeck;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
  .chat_msg2 {
    width: 22%;
    background: #ffffff;
    font-size: 16px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
  }
  .col-2 {
    font-size: 10px;
  }
  @media screen and (max-width: 576px) {
    .chat_msg1 {
      width: 100%;
      // font-size: 14px;
      border-radius: 10px;
      padding: 12px;
    }
    .chat_msg2 {
      width: 100%;
      background: #ffffff;
      // font-size: 14px;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 12px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
    }
  }
}

/******************************************* TextResponseStatic.js css end **************************************/


/******************************************* BrandingInformation css start **************************************/

// .branding {
  .chat_msg1 {
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
    @media screen and (max-width: 959px) {
      font-size: 13px;
      line-height: 22px;
    }
    // margin-bottom: 10px;
    border-radius: 10px;
    // .chat1 {
    //   font-family: foundersGroteskTextRegular;
    //   font-size: 28px;
    //   line-height: 40px;
    //   color: var(--polo-primary, #041e3a);
    //   @media screen and (max-width: 959px) {
    //     font-size: 18px;
    //     line-height: 24px;
    //   }
    // }
    .chat2 {
      font-family: foundersGroteskTextRegular;
      font-size: 16px;
      line-height: 30px;
      // margin-bottom: 30px;
      color: var(--polo-primary, #041e3a);
      @media screen and (max-width: 959px) {
        font-size: 13px;
        line-height: 22px;
        // margin-bottom: 20px;
      }
    }
  }
  .chat_msg2 {
    width: 22%;
    background: #ffffff;
    // font-size: 14px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
  }
  .col-2 {
    font-size: 10px;
  }
  .video {
    margin-top: 20px;
  }
  .branding-conclusion {  
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
    @media screen and (max-width: 959px) {
      font-size: 13px;
      line-height: 22px;
    }
  }
  @media screen and (max-width: 576px) {
    .chat_msg1 {
      width: 100%;
      // font-size: 14px;
      // margin-bottom: 10px;
      border-radius: 10px;
    }
    .chat_msg2 {
      width: 100%;
      background: #ffffff;
      // font-size: 14px;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 12px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
    }
    .video {
      width: 325px;
      height: 300px;
    }
  }
// }
/******************************************* BrandingInformation css end **************************************/


/********************************************** Useronboard.js css start ***************************************/

//----useronboard------start-------------------
// .useronboard {
//   background-image: url(../../public/static/assets/useronboard.svg);
//   animation-duration: 4s;
//   background-repeat: no-repeat;
//   // background-position: top center !important;
//   background-size: cover;
//   min-height: 100%;
//   min-width: 100%;
//   animation: userOnboardBackground 3s ease-in forwards;
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;

//   .closebtn {
//     margin-left: 90%;
//     margin-top: 10px;
//     border: none;
//     background:none;
//   }
//   .msg::placeholder {
//     color: #9D9FA3;
//   }
//   .logo {
//     text-align: center;
//     color: white;
//     font-size: 80px;
//     font-family: leJeuneDeck;
//     // margin-bottom: -12px;
//     // margin-top: -0.5rem;
//     // margin-left: 2rem;
//     opacity: 100%;
//   }
//   .send-arrow-position {  
//     right: 15px !important;
//   }

//   //common breakpoint mobile//
//   @media screen and (max-width:576px){
//     .logo{
//       height: 63px !important;
//       width: 226px !important;
//     }
//     .logo-img{
//       height: 63px !important;
//       width: 226px !important;  
//       max-width: none;
//     }
//     .Corresel { 
//       bottom: -1.5rem !important; 
//     }
//     .chatbox.box-msg{
//       top:48.88% !important;
//     }
//   }
//   @media screen and (max-height:660px){   
//     overflow: auto !important; 
//     .Corresel { 
//       position: relative !important;
//       top: 13rem;
//     }     
//   }
//   @media screen and (min-width:577px) and  (max-width:959px){
//     .logo{                           // logo scaling
//       height: auto !important;
//       width: 47% !important;
//       left:26.4% !important; 
//     }
//     .Corresel { 
//       bottom: 0rem !important; 
//     }
//  }
//  @media screen and (min-width:577px) and  (max-width:767px){
  
//   .chatbox.box-msg{
 
//      top:48.88% !important;
  
    
//    }
//    .arrow-dist
//    {
//      margin-top: 5.5%!important;

//    }
 

//  }
//  @media screen and (min-width:768px) and  (max-width:958px){
  
//   .chatbox.box-msg{
//      top:55.88% !important;    
//    }
//    .arrow-dist
//    {
//      margin-top: 4.5%!important;

//    }
//  }

//   @media screen  and  (max-width:959px)
//   {
//     background-position: 3% center !important;
//     .logo{
//       position: fixed !important;
//       top:23.88% !important;
//     }
//     .chatbox.box-msg
//     {
//       height: 50px;
//       margin: 20px;
//       width: 90% !important;
//       margin-left: 20px;
//       margin-right: 20px;
//       position:fixed;
//       border-radius: 15px;
//       gap: 10px;
//       top:40%;
//     }
//     .arrow-dist
//      {
//       margin-top: 8%;
//      }
//     .msg
//      {
//       padding:  0px, 18px, 0px, 18px !important;
//       height: 50px;
//       margin: 20px !important ;
//       font-size: 14px;
//       line-height: 18px;
//       padding-top: 176px;
//       min-height: 50px !important;
      
//      }
//     .arrow_long{
//       height: 22px !important;
//       width: 22px !important;
//      }
    
//     .logo{
//       position: fixed;
//       top: 215px;
 
//     }
//     .btn{
//       height: 32px !important; 
//       width: 117px !important;
//       font-size: 11px !important;
//       position: fixed;
//       bottom: 40px !important;  
//     }
//     .Corresel { 
    
//       position: fixed;
//       bottom:40px;
//       width:375px !important;
//       height: 210px !important;
//       margin: 25px;       
//     }
//      .carol_content {
//       padding: 42px 45px!important;
//       padding-top: 2px !important;
//       }
//     .carousel-control-next-icon,
//     .carousel-control-prev-icon {
//     position: fixed;
      
//     }
//     .send-arrow-position {
//       right: 25px;
//     }
//     .Corresel .corouselslider {
//       width: 16px;
//       height:1.6px !important; 
//   }
  
//   }
//   @media screen  and (max-width:384px) 
//   {
//     .arrow-dist{
//       margin-top:10% !important;
//     }
//   }

//   @media screen and (min-width: 385px) and (max-width:412px)
//   {
//     .arrow-dist{
//       margin-top:9% !important;
//     }
//   }
//   @media screen and (min-width: 413px) and (max-width:576px)
//   {
//     .arrow-dist{
//       margin-top:6.5% !important;
//     }
//   }
  
//   //desktop//
//   @media screen  and (min-width:960px),screen and (min-height: 914px)

//   {
//     .logo{
//       height:108.85px;
//       width: 450px;
//       position:absolute;
//       top:18.5%;
//     }
  
//      .chatbox.box-msg{
//       position: absolute;
//       width: 911px;
//        top:58.88%;     
//      }
//      .arrow-dist
//      {
//       margin-top: 12px;
//      }
//     .msg{
//       height: 50px !important;
//       font-size: 18px !important;
//       min-height: 50px !important;
//       max-height: 170px !important;

//     }
//     .arrow_long { 
//       height: 36px;
//       width: 36px;
//     }
//     .btn{
//       height: 32px;
//       width: 117px;
//       font-size: 11px;
//       top:89.5%;
//       position: fixed;
//       bottom:6.22% ;
//     }
//     .chatbox span {
//       right: 6px;
//     }
//     .Corresel { 
//       height: 198px;
//       position: fixed;
//       bottom:56px;
//       margin-bottom: -1rem !important;
       
//     }
//     .carousel-control-next-icon,
//     .carousel-control-prev-icon {

//     margin-bottom:25px !important  ;
      
//     }
//     .send-arrow-position {
//       right: 25px;
//     }
//     .Corresel .corouselslider {
//       width: 13px;
//       height:1.8px !important;
//   }
    
//   }
//   @media only screen and (min-width: 500px) and (max-width: 961px){
//     background-position:  top left   !important;  
//   }
//   @media only screen and (min-width: 962px) and (max-width: 1212px){
//     background-position:  top left center  !important;  
//   }
//   @media only screen and (min-width: 1213px) and (max-width: 1440px){
//     background-position:  top  left !important;  
//   }

//   ///xr////
//   @media screen and (min-width: 412px) and (max-width: 429px) {
//     background-position: 3% center !important;
//     .carousel-control-next-icon,
//     .carousel-control-prev-icon {
   
//     margin-bottom:10px !important  ;
      
//     }
// }
//   .msg {
//     font-family: leJeuneDeck;
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     flex: 1 0 0;
//     box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
//   }
  
//   .send-arrow-position {
//     right: 25px;
//   }

//   .btn {
//     border-radius: 20px;
//     background: transparent;
//     color: white;
//     font-family: foundersGroteskMonoRegular;
//     line-height: 14px;
//     display: inline-flex;
//     flex-direction: column;
//     text-transform: uppercase;
//     padding: 10px 20px 8px 20px;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;
//     align-self: stretch;
//     position: fixed;
//   }
//   .btn:hover{
//     background: white ;
//     color: #041E3A ;
//   }

//   .header_local {
//     top: 0;
//     width: 800px;
//     height: auto;
//   }

//   .Corresel {
//     background-color: white;
//     width: 350px;
//     min-height: 198.001px;
//     border-radius: 15px;
//     margin-top: 150px;
//     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
//     .carousel-inner {
//       height: 90%;
//     }
//     .carousel-item {
//       height: 100%;
//       text-align: center;
//     }
//     .carol_content {
//       padding: 1px 45px;
//     }
//     .carousel-control-next-icon,
//     .carousel-control-prev-icon {
//       width: 15px;
//       height: 15px;
//       margin-bottom: 25px;
      
//     }
//     .btn-close {
//       margin-left: 88%;
//       font-size: 10px;
//       margin-top: 10px;
//     }
//     .common_btn {
//       border: 0;
//       background: #041e3a;
//       padding: 2px 20px;
//       line-height: 29px;
//       font-size: 11px;
//       color: #ffffff;
//       border-radius: 20px;
//       font-family: foundersGroteskMonoRegular;
//       margin-top: 10px;
//     }
//     .heading1 {
//       font-family: leJeuneDeck;
//       font-size: 50px;
//       line-height: 24px;
//     }
//     .heading2 {
//       font-family: foundersGroteskTextRegular;
//     }
//     .commonheader {
//       font-family: foundersGroteskTextMedium;
//       line-height: 15px;
//       letter-spacing: 2px;
//     }
//     .corouselslider{
//       width: 14px;
//       height: 1.1px;
//     }
//     @media only screen and (min-device-width: 959px) {
//       margin-bottom: 0rem !important;
//     }
//     @media only screen and (max-device-width: 959px) {
//       margin-bottom: 2rem !important;
//     }
//   }
  
//   .message-fade {                                          
//     text-align: start;
//     top: 5px;
//     padding-left: 2rem;
//     font-size: 18px;
//     line-height: 24px;
//     color: #6D6F73;
//     animation: blink 3s linear infinite;
//     @media screen and (max-width:959px){
//       font-size: 14px;
//       line-height: 18px; 
//       top: 32px;
//     }
//     @media screen and (max-width:360px){
//       padding-right: 25px !important;
//       top: 23px !important;
//     }
//   }
// @keyframes blink {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: .5;
//   }
//   100% {
//     opacity: 1;
//   }
// }


//   .onboard_image {                                       //not used 
//     // max width phone potrait 576px, ipad potrait 768px landscape 1024, monitor 1200px
//     @media screen and (max-width: 576px) {
//       img {
//         width: 250px;
//         margin-bottom: 10px;
//       }
//     }
//   }
//   .onboard_width {                                         //not used 
//     width: 60%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     @media screen and (max-width: 576px) {
//       width: 100%;
//       justify-content: space-between;
//     }
//     text-align: center;
//   }
// }
.chatboxes {                                //not used 
  width: 100%;
}

// .common_btn {
//   border: 0;
//   background: #041e3a;
//   padding: 8px 25px;

//   font-size: 14px;
//   color: #ffffff;
//   border-radius: 25px;
// }

// ============================================================================================
// ================== Input Placehonder CSS [chatinterface and useronboard]  ==================
// ============================================================================================

// .send-arrow-position{
//   position: absolute;
//   right: 15px;
// }

// .send-arrow-position.enabled{
// cursor: pointer;
// border:none;
// background:none;
// }

// .send-arrow-position.disabled{
// border:none;
// background:none;
// }

// .active {
//   opacity: 1;
// }

//--------userboard----end-------------------------------------------------


// =======================================================================================================
//                              USERONBOARD ANIMATIONS START
// =======================================================================================================

// @keyframes userOnboardBackground{
//   from {
//     transform:scale(1);
//     opacity:0;
//     background-color: white;    
//   }
//   to {
//     transform: scale(1.02);
//     opacity: 1;
//   }
// }

// .userOnboardBackgroundMain{
//   animation: userOnboardBackground 3s ease-in forwards;
// }


@keyframes backOutImg{
  from {
    transform:translate(0px,-5px) scale(1.02);
    opacity:1;     
  }
  to {
    transform:translate(-15,-5px) scale(1);
    opacity: 0;
    // background-color: white;  
  }
}

.backgroundOut{
  animation: backOutImg 1s ease-out forwards;
}

// .animateSearch{
//   animation: movefromBottomSearch 1.5s forwards ease-in;
//  }

//   @keyframes movefromBottomSearch {
//   0% {
//     opacity: 0;
//     transform: translateY(5rem);
//   }
//   80% {
//     opacity: 0.5;    
//   }
//   100% {
//     opacity: 1;
//     transform: translate(0);
//   }  
// }

// .animateSearchOut{
//   animation: moveToBottomSearch 1s forwards ease-in;
//  }

// @keyframes moveToBottomSearch {
//   0% {
//     opacity: 1;
//     transform: translate(0);
//   }
//   80% {
//     opacity: 0.5;    
//   }
//   100% {
//     opacity: 0;
//     transform: translateY(5rem);
//   }  
// }

// .reveal-container{          //not used
//   overflow: hidden;
// }

// @keyframes imgTypingEffect {         //not used
//   from{
//     clip-path: polygon(0 0,0 100%,0% 100%,0% 0%);
//   }
//   to{
//     clip-path: polygon(0 0,100 0,100% 100%,0% 100%);
//   }
// }

// .onboard-loader{
//   width: 8px;
//   height: 8px;
//   position:absolute;
//   top: 25px;
//   right: 30px;
//   aspect-ratio: 1;
//   border-radius: 50%;
//   animation: loader 1s infinite linear alternate;
//   @media screen and (max-width: 959px) {
//     top: 48px;
//   }
// }

// @keyframes loader {
//   0% {
//     box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
//     background:#6D6F73;
//   }
//   33% {
//     box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
//     background: #0002;
//   }
//   66% {
//     box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
//     background: #0002;
//   }
//   100% {
//     box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
//     background: #6D6F73;
//   }
// } 
// .prompt-content-fadein{
//   animation: prompt-content-fadein;
//   animation-fill-mode: forwards;
//   animation-delay: 0.4s;
//   animation-duration: 0.4s;
//   opacity:0;
//  }
// @keyframes prompt-content-fadein {
//   0%   { opacity:0; }
//   100% { opacity:1; }
// }

// .prompt-fadein{
//   animation: prompt-fadein 0.83s forwards ease-in;
  
// }
// @keyframes prompt-fadein {
//   0% {
//     transform: translateY(295px);
//     opacity: 0;
//       }
//   100% {
//     transform: translate(0);
//     opacity: 1;
//   }  
// }
// .prompt-fadeout{
//   animation: prompt-fadeout 0.83s forwards ease-out;
  
//   }
// @keyframes prompt-fadeout {
//   0% {
//     transform: translate(0);
//     opacity: 1;
//   }
//   100% {
//     transform: translateY(295px);
//     opacity: 0;
//     display: none;
//       }  
// }

// .prompt-fadein{
//   animation: prompt-fadein 0.5s forwards ease-in;
// }
// @keyframes prompt-fadein {
//   0% {
//     transform: translateY(15rem);
//   }
//   100% {
//     transform: translate(0);
//   }  
// }
// .prompt-fadeout{
//   animation: prompt-fadeout 0.5s forwards ease-in;
// }
// @keyframes prompt-fadeout {
//   0% {
//     transform: translate(0);
//   }
//   100% {
//     transform: translateY(15rem);
//   }  
// }
.chatbox {                                             
  text-align: center;
  width: 100%;
  border-radius: 13px;
  input {
    width: 100%;
  }
  .form-control {                                      
    padding: 12px 60px 12px 24px;
    font-family: leJeuneDeck;
    font-size: 18px;
    line-height:24px;
    border-radius: 13px;
    border: none;
    max-height: 170px;
    min-height: 60px;
    resize:none;
    position: absolute;
    bottom: -55px;
    top: auto;
    overflow: auto;
    @media screen and (max-width: 959px){
      padding: 15px 40px 15px 18px;
      border-radius: 10px;
      font-size: 14px;
      line-height:18px;
      min-height: 50px !important;
      max-height: 132px !important;
      bottom: -48px;
    }
  }
  .form-control:focus {
    box-shadow: none;
  }
  span {
    position: absolute;
    padding: 12px;
    right: 15px;
  }

  .send-arrow-position{                                   
    position: absolute;
    // right: 15px;
    // top: 1px;
    // @media screen and (max-width: 959px){
    //   transform: translateY(-2%) !important
    // }
  }

.send-arrow-position.enabled{                           
  cursor: pointer;
  border:none
}

.send-arrow-position.disabled{                              
  border:none
}
 .textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    background-color: yellow;
  }
}

// =======================================================================================================
//                              USERONBOARD ANIMATIONS END
// =======================================================================================================

/************************************** Useronboard.js css end *************************************************/

/************************************** ChatInterface.js css start *************************************************/

// input.form-control.form-focus:focus {
//   box-shadow: none;
// }
// input.form-control.form-focus::placeholder {
//   color: #6D6F73;
// }
// .chatbox {                                             
//   text-align: center;
//   width: 100%;
//   border-radius: 13px;
//   input {
//     width: 100%;
//   }
//   .form-control {                                       
//     padding: 12px 70px 12px 24px;
//     font-family: leJeuneDeck;
//     font-size: 18px;
//     line-height:24px;
//     border-radius: 13px;
//     border: none;
//     max-height: 170px;
//     min-height: 60px;
//     resize:none;
//     position: absolute;
//     bottom: -55px;
//     top: auto;
//     overflow: auto;
//     @media screen and (max-width: 959px){
//       padding: 15px 60px 15px 18px;
//       border-radius: 10px;
//       font-size: 14px;
//       line-height:18px;
//       min-height: 50px !important;
//       bottom: -48px;
//     }
//   }
//   .chat{
//     padding-top: 17px;
//   }
//   .form-control:focus {
//     box-shadow: none;
//   }
//   span {
//     position: absolute;
//     padding: 12px;
//     right: 15px;
//   }

//   .send-arrow-position{                                   
//     position: absolute;
//     right: 15px;
//     top: 1px;
//     @media screen and (max-width: 959px){
//       top: 4px;
//     }
//   }

// .send-arrow-position.enabled{                           
//   cursor: pointer;
//   border:none
// }

// .send-arrow-position.disabled{                            
//   border:none
// }
//  .textarea {
//     width: 100%;
//     height: 150px;
//     padding: 12px 20px;
//     box-sizing: border-box;
//     border: 2px solid #ccc;
//     border-radius: 4px;
//     background-color: #f8f8f8;
//     font-size: 16px;
//     resize: none;
//     background-color: yellow;
//   }
// }

// .homepageresize {
//   height: calc(100vh - 118px) !important;
// }

// .homepage {                                                         
//   height: calc(100vh - 102px);
//   overflow: unset;
//   @media screen and (max-width: 959px){
//     height: calc(100vh - 88px);
//   }
//   .chatwindow {                                                    
//     font-family: leJeuneDeck;
//     .conversationlist {                                            
//       height: calc(100vh - 84px);  
//       @media screen and (max-width: 959px){
//       height: calc(100vh - 70px);
//       }
//       .min-height{
//         min-height: calc(100vh - 140px);
//         @media screen and (max-width: 576px){
//           min-height: calc(100vh - 140px);  
//         }
//         @media screen and (min-width: 577px) and (max-width: 768px){
//         min-height: calc(100vh - 193px);
//         } 
//         @media screen and (min-width: 769px) and (max-width: 959px){
//           min-height: calc(100vh - 140px);  
//         }
//       }
//     }
//   }
//   .chatbox {
//     background: linear-gradient(#e4d8c9, #ffffff);
//     text-align: center;
//     width: 100%;
//     bottom: 0;
//     input {
//       width: 100%;
//     }
//     span {
//       position: absolute;
//       cursor: pointer;
//       padding: 12px;
//       right: 0;
//     }
//   }
// }
// .loader-div{
//   margin-top: 4px;
//   @media screen and (max-width: 959px){
//     margin-top: 24px;
//   }
// }
// .help-links{
//   padding-bottom:16px;
//   margin-top: -10px;
//   @media screen and (max-width: 959px){
//     padding-bottom:6px; 
//     margin-top: 0px;
//   }
// }
// .dots-loader {
//   width: 7px;
//   height: 7px;
//   margin-right: 5px;
//   aspect-ratio: 1;
//   border-radius: 50%;
//   animation: dl 1s infinite linear alternate;
//   @media screen and (max-width: 959px){
//     width: 5px;
//     height: 5px; 
//   }
// }
// @keyframes dl {
//   0% {
//     box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
//     background:#6D6F73;
//   }
//   33% {
//     box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
//     background: #0002;
//   }
//   66% {
//     box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
//     background: #0002;
//   }
//   100% {
//     box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
//     background: #6D6F73;
//   }
// }
// .loader-text{
//   color: #6D6F73;
//   font-family: foundersGroteskTextRegular;
//   font-size: 12px;
//   font-style: normal;
//   line-height: 10px;
//   margin-left: 21px;
//   @media screen and (max-width: 959px){
//     font-size: 10px;
//     margin-left: 18px;
//   }
// }
// .footer-container{
//   @media screen and (max-width: 576px) { 
//   padding: 0px 20px;
//   }
// }
// .ms-20{
//   margin-left: 0px;
//   @media screen and (max-width: 576px) {
//   margin-left: 20px !important;
//   }
// }
// .footer-tags {
//   @media screen and (max-width: 576px) {
//     height: 56px;
//     flex-wrap: wrap;
//     flex-direction: column;
//     overflow-x: auto;
//     width: 110%;
//     margin-left: -20px;
//   }
//   @media screen and (min-width: 577px) and (max-width: 1023px) {
//     flex-wrap: wrap;
//     justify-content: flex-end;
//   }
// }
// @media screen and (max-width: 576px) {
// .footer-tags::-webkit-scrollbar {
//   display: none;
// }
// }
// .prompts{
//   @media screen and (max-width: 470px){
//     padding-left: 2px !important;
//     padding-right: 3px !important;
//   }
//   @media screen and  (min-width: 471px) and (max-width: 576px){
//     padding-left: 2px !important;
//     padding-right: 11px !important;
//   }
//   @media screen and (min-width: 768px) and (max-width: 1023px) {
//       margin-bottom: 4px;
//   }
// }
// .footer_btn {
//   border: 2px;
//   border-radius: 5px;
//   border: 1px solid rgba(4, 30, 58, 0.50);
//   background: rgba(255, 255, 255, 0.00);
//   padding: 6px 16px 8px 16px;
//   margin: 0px 12px 4px 0px;
//   color: var(--polo-primary, #041e3a);
//   &:hover {
//     border-radius: 5px;
//     border: 1px solid rgba(4, 30, 58, 0.00);
//     background: #FFF;
//   }
//   @media screen and (min-width: 577px) and (max-width: 1023px) {
//     margin: 0px 12px 10px 0px !important; 
//   }
//   @media screen and (max-width: 959px){
//     padding: 8px 14px 12px 14px;
//   }
// }

// @media screen and (max-width: 959px) {
//   .footer_search{                                                   
//    padding-bottom: 16px;
//   }
//   .arrow_long {
//     height: 22px;
//     width: 22px;
//   }
//   .footer-gradient{
//     height: 20px;
//     background: linear-gradient(0deg, #E1E1E1 0%, rgba(223, 223, 223, 0.00) 100%);
//     position: sticky;
//   }
// }
// @media screen and (min-width: 960px){
//   .footer_search{
//     padding-bottom: 24px;
//   }
//   .footer-gradient{
//     height: 24px;
//     background: linear-gradient(0deg, #E1E1E1 0%, rgba(223, 223, 223, 0.00) 100%);
//     position: sticky;
//   }
// }
// .footer_search {                                                 
//   font-family: leJeuneDeck;
//   background: linear-gradient(0deg, #E1E1E1 0%, rgba(223, 223, 223, 0.00) 100%);
//   @media screen and (max-width: 576px) {
//     .chatbox span {
//       padding-right: 2px;
//     }
//   }
//   @media screen and (min-width: 577px) and (max-width: 767px) {
//     .footer-tags {
//       height: 60px;
//       flex-wrap: wrap;
//       flex-direction: column;
//       overflow-x: auto;
//     }
//     .footer-tags::-webkit-scrollbar {
//       display: none;
//     }
//   }
  
//   button.stop_btn {
//     background: #e9e9e9;
//     border: 2px;
//     box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
//     border-radius: 5px;
//     padding: 10px 16px 7px 16px;
//     color: var(--polo-primary, #041e3a);
//     margin-top: 15px;
//     margin-bottom: 20px;
//     line-height: 13px;
//     position: absolute;
//     right:15px;
//     top:-7px;
//   }
  
// }
// ============================================================================================
// ================== Input Placehonder CSS [chatinterface and useronboard]  ==================
// ============================================================================================

.send-arrow-position{
  position: absolute;
  right: 15px;
}

.send-arrow-position.enabled{
cursor: pointer;
border:none;
background:none;
}

.send-arrow-position.disabled{
border:none;
background:none;
}

// //Chat bubble animation
// .chatbubbleAnimate {
//   position: relative;
//   animation-name: chat-msg;
//   animation-duration: 3s;
//   }
// @keyframes chat-msg {
//   0% {
//     top: 350px;
//   }
//   30% {
//     top: 300px;
//   }
//   100% {
//     top: 0px;
//   }
// }

// .page-container {
//   overflow-y: auto;
// }
// @media screen and (max-width: 576px) {
//   .page-container {
//     padding: 0px 20px;
//     overflow-y: auto;
//   }
// }
/******************************* ChatInterface.js css end *******************************************/



/*************************************Home.js css start ************************************************/
.homepage {                                                         
  // height: calc(100vh - 102px);
  // overflow: unset;
  // @media screen and (max-width: 959px){
  //   height: calc(100vh - 85px);
  // }
  // @media screen and (max-height: 650px){
  //       height: calc(100vh - 95px);
  // }
  .chatwindow {                                                    
    font-family: leJeuneDeck;
    // .conversationlist {                                            
      // height: calc(100vh - 84px);  
      // @media screen and (max-width: 959px){
      // height: calc(100vh - 67px);
      // }
    // }
  }
  .chatbox {
    background: linear-gradient(#e4d8c9, #ffffff);
    text-align: center;
    width: 100%;
    bottom: 0;
    input {
      width: 100%;
    }
    span {
      position: absolute;
      cursor: pointer;
      padding: 12px;
      right: 0;
    }
  }
}
.footer_search {                                                 
  font-family: leJeuneDeck;
  // background: linear-gradient(0deg, #E1E1E1 0%, rgba(223, 223, 223, 0.00) 100%);
  @media screen and (max-width: 576px) {
    .chatbox span {
      padding-right: 2px;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 767px) {
    .footer-tags {
      height: 60px;
      flex-wrap: wrap;
      flex-direction: column;
      overflow-x: auto;
    }
    .footer-tags::-webkit-scrollbar {
      display: none;
    }
  }
  
  button.stop_btn {
    background: #e9e9e9;
    border: 2px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 10px 16px 10px 16px;
    color: var(--polo-primary, #041e3a);
    margin-top: 15px;
    margin-bottom: 20px;
    line-height: 13px;
    position: absolute;
    right:15px;
    top:-9px;
    @media screen and (max-width: 959px) {
      padding: 6px 12px 6px 12px;
      border-radius: 4px;
      top:-7px;
    }
  }
  
}
@media screen and (min-width: 960px){
  .footer_search{                                                    
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 959px) {
  .footer_search{                                                   
   padding-bottom: 16px;
  }
}
/*************************************Home.js css end ************************************************/



/****************************** ProductListingCategory css start ****************************************/

// .brandingcss{
//   height:320px;
//   width:320px;
//   img {
//     width: 100%;
//     height: 100%;
//   }
//   @media screen and (max-width: 576px) {
//   height: 280px;
//   width: 282px;
//   }   
// }
// .singleCatItem {                                  //not used
//   padding: 70px 112px 70px 112px;

//   .badge-text {
//     font-size: 18px;
//     line-height: 24px;
//   }
// }
// .badge-style {
//   width: 150px;
//   height: 33px;
//   padding: 8px 18px 7px 18px;
//   border-radius: 100px;
//   margin-top: 15px;
//   @media screen and (max-width: 959px) {
//     margin-top: 0px !important;
//   }
// }
// .product-detail.truncate {
//   color: var(--polo-primary, #041e3a);
//   font-size: 18px;
//   line-height: 32px;
//   margin-top: 15px;
// }
// .icon-gap {
//   gap: 7px;
// }

// @media (max-width: 576px){
//   .sc-div{
//     margin-left: -18px !important
//   }
// }
// .item-div {
//   margin-top: -8px;
//   margin-left: -2rem;
// }
// .product-header-title {
//   font-size: 30px;
//   line-height: 36px;
//   margin-top: 2px;
//   color: #041E3A;
//   font-style: normal;
//   font-weight: 700;
//   text-transform: uppercase; 
//   font-family: foundersGroteskTextBold;
//   @media screen and (max-width: 959px){
//     font-size: 18px;
//     line-height: 24px; 
//   }
// }

// .sub-section {                           //not used
//   .heading-sub {
//     font-family: Arial, Helvetica, sans-serif;
//     color: var(--bs-para-color);
//     font-weight: bold;
//     font-size: 30px;
//   }
//   .para-sub {
//     font-family: Arial, Helvetica, sans-serif;
//     font-weight: 300;
//     font-size: 18px;
//     color: var(--bs-para-color);
//   }
// }

// .sc_product_details {
//   margin-top: 25px;
// }
// .sc-main {                                   
//   margin-top: 40px;
//   margin-left: 9px;
// }
// .section_margin {
//   margin-top: 3rem;
// }
// .branding-div {
//   padding-left: 32px;
//   color: var(--polo-primary, #041e3a);
//   font-size: 18px;
//   line-height: 32px;
// }
// @media screen and (max-width: 576px) {
//   .main-section {
//     .para-main {
//       font-family: leJeuneDeck;
//       font-size: 18px;
//       font-style: normal;
//       line-height: 24px;
//       color: var(--polo-primary, #041e3a);
//     }
//   }
//   .branding-div {
//     font-size: 14px;
//     line-height: 24px;
//   }
// }
// @media screen and (min-width: 960px) {
//   .hr-divider{
//     margin-top: 40px;
//     margin-bottom: 40px;
//   }
//   .truncate {
//     margin-top: 15px !important;
//   }

// }
// @media screen and (max-width: 959px) {
//   .hr-divider{
//     margin-top: 30px;
//   }
//   .truncate {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 2; /* number of lines to show */
//     line-clamp: 2;
//     -webkit-box-orient: vertical;
//     font-size: 14px !important;
//     font-weight: 400;
//     line-height: 24px !important;
//     margin-top: 10px !important;
//     margin-bottom: 18px !important;
//     color: var(--polo-primary, #041e3a);
//   }
//   .show-truncate-text {
//     overflow: visible; 
//     display: block;
//     white-space: normal;
//     height:auto;
//   }

//   .product-cat-Text {
//     color: var(--polo-primary, #041e3a);
//     font-family: foundersGroteskTextMedium;
//     font-size: 10px !important;
//     margin-top: 10px !important;
//     line-height: 20px !important;
//     letter-spacing: 2px !important;
//   }
//   .main-section {
//         .para-main {
//           font-family: leJeuneDeck;
//           font-size: 18px;
//           font-style: normal;
//           line-height: 24px;
//           color: var(--polo-primary, #041e3a);
//         }
//       }
// }
// // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ FONT STYLE SINGLE CAT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

// .product-cat-Text {
//   font-size: 13px;
//   line-height: 20px;
//   letter-spacing: 3px;
//   color: var(--polo-primary, #041e3a);
// }

// .my-item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .sc-subdiv{
//   margin-top:-6%;
//   @media screen and (max-width: 576px) {
//     margin-top:-19%; 
//   }
//   @media screen and (min-width: 577px) and (max-width: 767px){
//     margin-top:-17%; 
//   }
//   @media screen and (min-width: 768px) {
//     display:flex;
//   }
// }
// .sc-img-div{
//   @media screen and (min-width: 768px) and (max-width:991px){                                /*  MD breakpoint  */
//       margin-top: -13%;
//     }
//     @media screen and (min-width: 992px) and (max-width: 1199px){                           /* LG breakpoint  */
//       margin-top: -9%; 
//     } 
//     @media screen and (min-width: 1200px) and (max-width: 1399px) {                        /* XL breakpoint  */
//     margin-top: -6%; 
//     }
//     @media screen and (min-width: 1400px){                                                 /* XXL breakpoint  */
//     margin-top: -2%;
//     }
//   }
//   .product-count {
//     font-family: Le Jeune Deck;
//       margin-bottom: 20px;
//       // margin-top: 40px;
//       font-size: 80px;
//       font-style: normal;
//       line-height: 80px;
//       color: var(--polo-primary, #041e3a);
//       @media screen and (max-width: 959px){
//         font-family: leJeuneDeck;
//         color: var(--polo-primary, #041e3a);
//         font-size: 75px;
//         font-style: normal;
//         line-height: 85px;
//         margin-top: 20px !important;
//       }
//     @media screen and (max-width: 576px) {
//       margin-left: -2rem;
//     }
//   }


  
// ***********************************************************************************************************************************************
//        ************************ ANIMATION  CSS  SCProduct Details******************************************************************
// ***********************************************************************************************************************************************


// @-webkit-keyframes fadeinout {
//   0%,
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @keyframes fadeinout {
//   0%,
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .moodImgFadeInAndOut {
//   -webkit-animation: fadeinout 0.7s linear forwards;
//   animation: fadeinout 0.7s linear forwards;
// }

// .indexFade {
//   -webkit-animation: fadeinout 0.8s linear forwards;
//   animation: fadeinout 0.8s linear forwards;
// }

// .productImgFadeInAndOut {
//   -webkit-animation: fadeinout 1s linear forwards;
//   animation: fadeinout 1s linear forwards;
// }

// .productFade {
//   -webkit-animation: fadeinout 12s linear forwards;
//   animation: fadeinout 12s linear forwards;
// }

// .mediaImgFade {
//   -webkit-animation: fadeinout 15s linear forwards;
//   animation: fadeinout 15s linear forwards;
// }

// .animate1 {
//   animation: fadeInUp 1s forward;
// }

// @keyframes fadeInUp {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// .animate2 {
//   animation: fadeInUp2 5s forward;
// }

// @keyframes fadeInUp2 {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// .animate3 {
//   animation: fadeInUp3 10s forward;
// }

// @keyframes fadeInUp3 {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// .animate4 {
//   animation: fadeInUp4 15s forward;
// }

// @keyframes fadeInUp4 {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// .animate {
//   animation: fadeInUp 5s forwards;
// }

// @keyframes fadeInUp {
//   from {
//     opacity: 0;
//     transform: translateY(50px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// .content{
//   opacity: 0;
//   animation:fadeInContent ease-out 0.5s forwards;
// }

// @keyframes fadeInContent{
//   0%{opacity: 0;}
//   100%{opacity: 1;}
// }

// .content:nth-child(1){
//   animation-delay: 0.5s;
// }
// .content:nth-child(2){
//   animation-delay: 0.8s;
// }

// .content .content-child{
//   opacity: 0;
//   animation : fadeInInner ease-out 3s forwards;
// }

// @keyframes fadeInInner{
//   0%{opacity: 0;}
//   100%{opacity: 1;}
// }

// .type1{
//   opacity: 0;
//   animation:fadeInType ease-out 2s forwards;
// }

// @keyframes fadeInType{
//   0%{opacity: 0;}
//   100%{opacity: 1;}
// }

// .type1:nth-child(1){
//   animation-delay: 5s;
// }
// .type1:nth-child(2){
//   animation-delay: 0.5s;
// }
// .type1:nth-child(3){
//   animation-delay: 0.5s;
// }
// .type1:nth-child(4){
//   animation-delay: 0.5s;
// }

// .fadeInOut{                                              
//   animation: fadeInOuts 2s ease-in-out forwards;
// }

// @keyframes fadeInOuts {
//   0%{
//       opacity: 0;
//   }
//   100%{
//       opacity: 1;
//   }
// }
/******************************** ProductListingCategory css end ****************************************/


/******************************** MultiCategoryListing css start ****************************************/

// .product-header-title {                            
//   font-size: 30px;
//   line-height: 36px;
//   margin-top: 2px;
//   color: #041E3A;
//   font-style: normal;
//   font-weight: 700;
//   text-transform: uppercase; 
//   font-family: foundersGroteskTextBold;
//   @media screen and (max-width: 959px){
//     font-size: 18px;
//     line-height: 24px; 
//   }
// }
// .borders-dotted {                                       
//   border-bottom: 1px dashed #777;
//   visibility: visible;
// }
// .branding-img{
//   margin-right: 72px;
//   @media screen and (min-width: 1200px) and (max-width: 1399px)  {
//     margin-right: 25px;
//   }
//   @media screen and (min-width: 1400px) {
//     margin-right: -35px; 
//   }
// }

// .m-vLine {
//   visibility: hidden;
//   border-left: 1px dashed #777;
// }

// .item-count {
//   display: flex;
//   align-items: start;
//   justify-content: start;
// }

// .img-res-multi {
//   width: 160px;
//   height: 224px;
// }

// .multi-img-gap {
//   gap: 3px;
// }

// .product-detail {                                      
//   font-size: 18px;
//   line-height: 32px;
//   color: #041E3A;
//   font-style: normal;
//   font-family: leJeuneDeck;
//   @media screen and (max-width: 959px) {
//     font-size: 14px;
//     line-height: 24px;
//   }
// }
// @media screen and (max-width: 576px) {
// .img-res-multi {
//     width: 115px;
//     height: 161px;
//   }
// }
// @media screen and (min-width: 375px) and (max-width: 844px) {
//   .multi-img-gap {
//     gap: 1px;
//   }

//   .withoutMediaTextAlign {
//     margin-left: 15px;
//   }
// }

// .mcat-product-count {
//   font-family: Le Jeune Deck;
//     margin-bottom: 20px;
//     font-size: 80px;
//     font-style: normal;
//     line-height: 80px;
//     color: var(--polo-primary, #041e3a);
//   @media screen and (max-width: 959px) {
//     font-family: leJeuneDeck;
//     color: var(--polo-primary, #041e3a);
//     font-size: 75px;
//     font-style: normal;
//     line-height: 85px;
//   }
// }
// .container-margin .multi-container {
//   .img-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

// .main-multi-section {
//   .para-main {
//     font-family: leJeuneDeck;
//     font-weight: 400;
//     font-size: 28px;
//     line-height: 40px;
//     color: $bs-head-color;
//   }
//   .heading-main {
//     font-family: "Lato", sans-serif;
//     color: var(--bs-para-color);
//   }
//   @media screen and (max-width: 959px) {
//     .para-main {
//       font-size: 18px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: 24px;
//     }
//   }
// }

// .multiproductdetails {
//   .container-margin {
//     padding-top: 30px;
//   }
//   .product-detail {
//     font-size: 18px;
//     line-height: 30px;
//     margin-bottom: 24px;
//     color: var(--polo-primary, #041e3a);
//   }
//   .borders-dotted {
//     border-bottom: 1px dashed #777;
//     visibility: visible;
//     margin-left: 45px;
//     margin-bottom: 40px;
//   }
//   .multi-cat-margin {
//     margin-left: 45px;
//     width: 94%;
//     margin-top: -1.4rem;
//   }
//   .details-div {
//     margin-left: 4rem;
//     margin-top: 15px;
//   }
//   @media screen and (max-width: 576px) {
//     .product-count {
//       margin-top: 0px;
//     }
//     .product-header-title {
//       font-size: 20px;
//       line-height: 30px;
//     }
//     .borders-dotted {
//       visibility: hidden;
//     }
//     .product-detail {
//       font-size: 14px;
//       line-height: 24px;
//       margin-bottom: 20px;
//     }
//     .hrdiv {
//       display: none;
//     }
//     .multi-cat-margin {
//       margin-left: 0px;
//       margin-top: 0px;
//       width: 100%;
//     }
//     .details-div {
//       margin-left: 0rem;
//     }
//   }
//   @media screen and (min-width: 579px) and (max-width: 767px) {
//     .product-detail {
//       font-size: 16px;
//       line-height: 24px;
//     }
//     .product-header-title {
//       font-size: 22px;
//       line-height: 30px;
//     }
//     .borders-dotted {
//       border-bottom: 1px dashed #777;
//       visibility: visible;
//       margin-bottom: 25px;
//       margin-left: 0px;
//     }
//     .multi-cat-margin {
//       margin-top: -1.4rem;
//       margin-left: 0px;
//       width: 100%;
//     }
//     .details-div {
//       margin-left: 0rem;
//       margin-top: 10px;
//     }
//   }
//   @media screen and (max-width: 1024px) and (min-width: 768px) {
//     .main-section .para-main {
//       font-size: 22px;
//       line-height: 34px;
//     }
//     .product-header-title {
//       font-size: 23px;
//       line-height: 30px;
//     }
//     .borders-dotted {
//       margin-left: 0px;
//     }
//     .multi-cat-margin {
//       margin-top: -1.4rem;
//       margin-left: 0px;
//       width: 100%;
//     }
//     .details-div {
//       margin-left: 0rem;
//     }
//   }
//   @media (min-width: 992px) {
//     .img-container {
//       overflow-x: auto;
//     }
//     .img-container::-webkit-scrollbar {
//       display: none;
//     }
//     .multi-cat-margin {
//       margin-left: 15px;
//       width: 94%;
//       margin-top: -1.4rem;
//     }
//     .details-div {
//       margin-left: 3rem;
//     }
//   }
// }

// //   ***********************    Media Query     *********************

// @media screen and (max-width: 576px) {

//   .img-container {
//     overflow-x: auto;
//   }
//   .img-container::-webkit-scrollbar {
//     display: none;
//   }

//   .m-vLine {
//     visibility: visible;
//     height: auto;
//     opacity: 75%;
//   }

//   .borders-dotted {
//     border: 1px dashed #777;
//     visibility: hidden;
//   }

//   .img-res {
//     width: 250px;
//   }

//   .item-count {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//   }
//   .container-margin {
//     padding-top: 20px;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .i-pad {
//     margin-left: -1.1rem;
//   }
// }
// @media screen and (min-width: 1400px) {
//   .i-pad {
//     margin-left: -3.1rem;
//   }
// }

// @media screen and (max-width: 667px) and (min-width: 375px) {
//   .img-container {
//     overflow-x: auto;
//   }
//   .img-container::-webkit-scrollbar {
//     display: none;
//   }
// }

// @media screen and (min-width: 390px) and (max-width: 844px) {
//   .img-container {
//     overflow-x: auto;
//   }
//   .img-container::-webkit-scrollbar {
//     display: none;
//   }
// }

// @media screen and (max-width: 1024px) and (min-width: 768px) {
//   .img-container {
//     overflow-x: auto;
//   }
//   .img-container::-webkit-scrollbar {
//     display: none;
//   }
// }
// .multi-cat-border{
//   margin-bottom: 40px;
//   margin-top: 40px;
//   @media screen and (max-width:959px){
//     margin-bottom: 30px;
//     margin-top: 30px; 
//   }
// }

/******************************** MultiCategoryListing css end ****************************************/



/*********************************GridMultiCategory css start /Gender Nuetral css end *******************************************/


// .multiGridCatItem {
//   .multiGridLayout {
//     .gridDec {
//       color: var(--Collection-Primary, #000);
//       font-size: 28px;
//       font-style: normal;
//       line-height: 40px; 
//       @media screen and (max-width: 959px) {
//         color: var(--Polo-Primary, #041E3A);
//         font-size: 18px;
//         line-height: 24px; 
//       }
//     }
//     .gridSubdesc {
//       color: var(--Collection-Primary, #000);
//       font-size: 18px;
//       line-height: 32px;
//       @media screen and (max-width: 959px) {
//         color: var(--Polo-Primary, #041E3A);
//         font-size: 14px;
//         line-height: 24px;
//       }
//     }
//   }
   
//   .primary-img-div::-webkit-scrollbar {
//     display: none;
//   }
  
//   .product {
//     .prod-type {
//       color: var(--Polo-Primary, #041E3A);
//       font-size: 20px;
//       font-style: normal;
//       line-height: 24px;
//       padding: 10px 0;
//       @media screen and (max-width: 959px) { 
//         font-size: 18px;
//         line-height: 24px;
//         padding: 5px 0;
//       }
//     }
//     .prod-dec {
//       color: var(--Polo-Primary, #041E3A);
//       font-size: 18px;
//       font-style: normal;
//       line-height: 32px;
//       @media screen and (max-width: 959px) {
//       font-size: 14px;
//       line-height: 24px;
//       }
//     }
//   }

//   .grid-text {
//     color: var(--Polo-Primary, #041E3A);
//     font-size: 18px;
//     font-style: normal;
//     line-height: 32px;
//     @media screen and (max-width: 959px) {
//     font-size: 14px;
//     line-height: 24px;
//     }
//   }
//   .slick-prev{
//     left: 15px;
//     z-index: 1;
//     top: 39%;
//     @media screen and (min-width: 768px) and (max-width: 1200px){
//       top: 33%;
//     }
//     @media screen and (min-width: 577px) and (max-width: 768px){                     // SM breakpoint
//       left: 10px;
//       top: 25%;
//     }
//     @media screen and (max-width: 576px){                                            // XS breakpoint
//     top: 36.5%;
//     }
//   } 
//   .slick-next {
//     right: 30px;
//     top: 39%;
//     @media screen and (min-width: 768px) and (max-width: 1200px){
//       top: 33%;
//     }
//     @media screen and (min-width: 577px) and (max-width: 768px){                   // SM breakpoint
//       right: 25px;
//       top: 25%;
//     }
//     @media screen and (max-width: 576px){                                           // XS breakpoint
//       right: 35px;
//       top: 36.5%;
//       }
//   }
//   .slick-prev:before,
//   .slick-next:before {
//     font-size: 26px;
//     opacity: 85%;
//     color: white;
//   }
//   .show-carousel{
//     @media screen and (max-width: 767px){
//       display: none;
//     }
//   }
//   @media screen and (max-width: 767px){
//     .primary-div{
//       width: 232px !important;
//     }
//     .primary-img-div{
//       flex-wrap: nowrap;
//       overflow-x: auto;
//     }
//   }
//   @media screen and (max-width: 576px){
//   .carousel-sm-div{
//     margin-left: -20px;
//     width: 109%;
//   }
//   .grid-ms-20{
//     margin-left: 20px;
//   }
//   .grid-me-20{
//     margin-right: 20px !important;
//   }
//  }
// }

// @media screen and (min-width: 375px) and (max-width: 667px) {
//   .multiCatItem {                            //not used
//     padding: 30px 20px 30px 20px;
//   }
// }
// .active {
//   opacity: 1;
// }
/*********************************GridMultiCategory css end /Gender Nuetral css end *******************************************/



/***************** SingleProductDetails css start / SpecificProductDetails css start  ***************************/
// .singleproduct {
//   .main-section {
//     .para-main {
//       font-family: leJeuneDeck;
//       font-weight: 400;
//       font-size: 28px;
//       line-height: 40px;
//       color: $bs-head-color;
//       padding-bottom: 32px;
//     }
//   }
//   .productdetails-div{
//     margin-bottom: 40px;
//   }
//   .divider {
//     margin: 4px 0px 11px 0px;
//   }
 
//   .divider_margin {
//     margin-bottom: 2px;
//   }
//   .icons_capsule {
//     border-radius: 20px;
//     margin-top: 20px;
//     padding: 8px 18px 7px 18px;
//   }
//   .product-heading {
//     font-family: foundersGroteskTextBold;
//     color: var(--polo-primary, #041e3a);
//     font-size: 30px;
//     font-style: normal;
//     line-height: 36px;
//     text-transform: uppercase;
//   }
//   .badge-text {
//     font-family: leJeuneDeck;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 18px;
//   }
//   @media (min-width: 1024px) {
//     .sub-text {
//       overflow: hidden;
//       text-overflow: ellipsis;
//       display: -webkit-box;
//       -webkit-line-clamp: 3; /* number of lines to show */
//       line-clamp: 3;
//       -webkit-box-orient: vertical;
//     }
//   }
//  .main-img {
//     width: 100%;
//     height: 100%;
//   }
//   .sub-text {
//     font-family: leJeuneDeck;
//     font-size: 18px;
//     line-height: 32px;
//     color: var(--polo-primary, #041e3a);
//   }
//   .sub-img {
//     display: none;
//   }
//   .display-img{
//     display: none;
//   }
//   .pic-div {
//     margin-bottom: 24px;
//   }
//   /* -----phone (portrait)----- */
//   @media (max-width: 576px) {
//     .main-img {
//       padding: 0;
//       display: none;
//     }
//     .pic-div {
//       flex-wrap: nowrap;
//       height: 207px;
//       overflow-x: auto;
//       width: auto;
//     }
//     .pic-div::-webkit-scrollbar {
//       display: none;
//     }
//     .productdetails-div{
//       margin-bottom: 0px;
//     }    
//     .sub-img {
//       height: 231px !important;
//       width: 231px;
//       display: block;
//     }
//     .display-img{
//       display: block;
//       height: 213px;
//       width: 213px;
//     }
//   }

//   /* ----iPads (portrait) ----*/
//   @media screen and (min-width: 768px) and (max-width: 990px) {
//     .main-img {
//       padding: 0;
//       display: none;
//     }
//     .pic-div {
//       flex-wrap: nowrap;
//       height: 465px;
//       overflow-x: auto;
//       width: auto;
//       margin-top: -40px;
//     }
//     .pic-div::-webkit-scrollbar {
//       display: none;
//     }
//     .sub-img {
//       display: block;
//     }
//     .display-img{
//       display: block;
//       height: 358px;
//       width: 358px;
//     }
//     .main-section .para-main {
//       font-size: 22px;
//       line-height: 26px;
//       padding-bottom: 0px;
//     }
//     .productdetails-div{
//       margin-bottom: 0px;
//     }
//   }
//   //---- phone (landscape)-----
//   @media screen and (min-width: 579px) and (max-width: 767px) {
//     .main-img {
//       padding: 0;
//       display: none;
//     }
//     .sub-img {
//       height: 268px !important;
//       width: 268px !important;
//       margin-top: 3rem !important;
//       display: block;
//     }
//     .display-img{
//       display: block;
//       height: 268px;
//       width: 268px;
//       margin-top: 6.2rem !important;
//     }
//     .pic-div {
//       flex-wrap: nowrap;
//       height: 465px;
//       overflow-x: auto;
//       width: auto;
//       margin-top: -7rem;
//       margin-bottom: -4rem !important;
//     }
//     .pic-div::-webkit-scrollbar {
//       display: none;
//     }
//     .productdetails-div{
//       margin-bottom: 0px;
//     }
//   }
//   @media screen and (max-width: 959px){
//   .product-heading {
//         font-size: 18px;
//         font-weight: 700;
//         line-height: 24px;
//     }
//     .sub-text {
//       font-size: 14px;
//       line-height: 24px;
//     }
//     .main-section .para-main {
//       font-size: 18px;
//       line-height: 24px;
//       padding-bottom: 16px;
//     }
//   }
   
// }
// .specificProduct {
//   padding-top: 0 !important;
// }
/***************** SingleProductDetails css end / SpecificProductDetails css end  ***************************/

// My File ANimations Start

@keyframes sectionOne {        //not used 
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.sec1AnimatedContent{                 //not used 
  opacity: 0;
  animation:sectionOne 1s ease-in-out forwards;
}

.sec1AnimatedContent:nth-child(1){
 animation-delay: 1s;
}
.sec1AnimatedContent:nth-child(2){
  animation-delay: 2s;
 }


// My file animation end


.typing-effect{                            //not used 
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(40,end);
}

@keyframes typing {
  from{
      width: 0;
  } 
  to{
      width: 100%;
  }
}

// Not used
.img1 {
  animation: fadeInImge ease-in 3s;
}

.img2 {
  animation: fadeInImge ease-in 7s;
}
.img3 {
  animation: fadeInImge ease-in 10s;

}

// Not used
@keyframes fadeInImge {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}


// .container-content{
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }

// .fixed-btn{
//   position: fixed;
//   bottom:25px;
// }
