.archive-container {
  margin: 0 auto;
//   @media screen and (max-width: 576px){
//   .archive_margin{
//     margin-left: 1.5rem;
//     @media screen and (max-width: 400px){
//       margin-left: 0.5rem !important;
//     }
//   }
// }
  // .archive-carousel{
  //   .slick-track {
  //     justify-content: center;
  //     -webkit-justify-content: center;
  //     -webkit-justify-content: space-around;
  //     // @media screen and (min-width: 400px) and (max-width: 430px){
  //     //   margin-left: 18%;
  //     //   justify-content: normal;
  //     //   -webkit-justify-content: normal;
  //     // }
  //   }
  // }
  .slick-slider .slick-track {
    display: flex;
    // justify-content: center;
    @media screen and (min-width:1204px){
        justify-content: center;
    }
      // gap: 8px;

    @media screen and (max-width: 767px){
      min-width: 275px !important;
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }

  .content-container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.01)
      );
    backdrop-filter: blur(10px);
    padding: 12px 24px;
    border-radius: 20px;
    height: 94px;
    margin: 0px 4px;
    @media screen and (min-width: 960px){
      &:hover {
        border: 1px solid #FFFFFF;
      }
    }
    // @media screen and (min-width: 577px){
    //   margin-right: 8px;
    // }
  }

  .textTruncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconStyle {
    font-size: 13px;
    color: white;
    height: 14px;
    width: 14px;
  }
  .fontStyle {
    font-family: foundersGroteskTextRegular;
    font-size: 13px;
    color: white;
    line-height: 20px;
  }
  .day-style-div{
    position:fixed;
    top: 3.8rem;
  }
  .three-dots{
    position: fixed !important;
    right: 7% !important;
    @media screen and (min-width:960px) {
      cursor: pointer;
    }
  }

  .dayStyle {
    font-family: foundersGroteskTextRegular;
    font-size: 8px;
    line-height: 24px;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
  }

  .tooltipBtn {
    height: 4px;
    border: 1px solid black;
    background-color: transparent;
    color: white;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 700;
  }

  .threeDotStyle {
    word-spacing: 5px;
  }
  .customTooltip {
    border: 1px solid white;
    border-radius: 10px;
    // overflow: hidden;
    // position: relative;
    // right: -12%;
    position: static;
    margin-right: -1.9rem;
    padding: 16px 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(0deg,rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
    backdrop-filter: blur(10px);
    display: block;
    z-index: 1000;
    width: fit-content;
    justify-self: end;
    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
    @media screen and (min-width: 577px) and (max-width: 620px) {
      margin-right: -2rem;
    }
    @media screen and (min-width: 700px) and (max-width: 767px) {
      margin-right: -1.7rem;
    }
    @media screen and (min-width: 900px) and (max-width: 991px) {
      margin-right: -1.7rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1059px) {
      margin-right: -2.1rem;
    }
    @media screen and (min-width: 1100px) and (max-width: 1159px) {
      margin-right: -1.85rem;
    }
    @media screen and (min-width: 1160px) and (max-width: 1399px) {
      margin-right: -1.8rem;
    }
  }
  .customTooltip ::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 52%;
    transform: translateX(-50%);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 10px solid white; 
  }
  .customTooltip ::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 52%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #514F40; 
  }
  .custom-xs-tooltip {
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px 10px;
    background: white;
    width: 100%;
  }

  .textTooltip {
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &_font {
      font-family: foundersGroteskMonoRegular;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
.newchat_txt{
  margin-bottom: 30px;
}
.modal-content_inner {
  .modal-header .cross {
    padding: 0 !important;
  }

  .btnChat {
    // width: 198px;
    // height: 52px;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    font-family: foundersGroteskMonoMedium;
    // color: #041e3a;
    border: 1px solid #041e3a !important;
  }
}
.modal_body_title2{
  font-family: foundersGroteskTextRegular;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--polo-primary, #041e3a);
}
// @media screen and (max-width: 767px) {
//   .slick-slide:nth-child(1) {
//     margin-left: 2.9rem !important;
//   }
// }
// .slick-slide:last-child {
//   @media screen and (min-width: 401px) and (max-width: 767px){
//       margin-right: 55px !important;
//     }
//   @media screen and (max-width: 400px) {
//     margin-right: 4rem !important;
//   }
// }
.slick-list {
  overflow: hidden;
  // @media screen and (max-width: 400px){
  //   width: 136% !important;
  // }
}
.slick-slide > div {
  // display: grid;
  place-items: center;
}
  // .slick-track{
    // @media screen and (max-width:375px){
      // justify-items:center;
      // margin-left:0 !important;
      // margin-right: auto !important;
    // }
    // @media screen and (min-width:376px) and (max-width:413px){
    //   justify-items:center;
    //   margin-left: 1rem !important;
    //   margin-right: auto !important; 
    // }
  // }
// @media screen and (max-width: 576px) {
//   .slick-slide:nth-child(n) {
//     width: 262px !important;
//   }
// }
// @media screen and (max-width: 576px){
// .archive-width{
//   width: 262px;
// }
@media screen and (min-width: 577px){
  .archive-container .slick-slide{
    width: 262px !important;
  }
}
.nextAr-arrow, .prevAr-arrow{
  position: absolute;
  height: 32px !important;
  width: 32px !important;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.01),
      rgba(255, 255, 255, 0.01)
    );
    top:30%;  
    // display: flex;
    text-align: center;
}
 
.prevAr-arrow{
  left: -2.2rem;
}
 
.nextAr-arrow{
  right: -2.4rem;
}
.tooltip_close{
  margin-top: 23px;
  margin-right: 5px;
}

//Tooltip modal animation 
 .tooltip-fadein{
    animation: tooltipFadein 0.5s forwards ease-in;
   }
    @keyframes tooltipFadein {
    0% {
      // opacity: 0;
      transform: translateY(10rem);
    }
    100% {
      // opacity: 1;
      transform: translate(0);
    }  
  }
  .tooltip-fadeout{
    animation: tooltipFadeout 0.5s forwards ease-in;
   }
    @keyframes tooltipFadeout {
    0% {
      // opacity: 1;
      transform: translate(0);
    }
    100% {
      // opacity: 0;
      transform: translateY(10rem);
    }  
  }
  .smlPad{
    padding-top: 0.5px;
  }
  