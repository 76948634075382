.singleproduct {
  .main-section {
    .para-main {
      font-family: leJeuneDeck;
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
      color: $bs-head-color;
      padding-bottom: 32px;
    }
  }
  .productdetails-div{
    margin-bottom: 40px;
  }
  .divider {
    margin: 4px 0px 11px 0px;
  }
 
  .divider_margin {
    margin-bottom: 2px;
  }
  .icons_capsule {
    border-radius: 20px;
    margin-top: 20px;
    padding: 8px 18px 7px 18px;
  }
  .product-heading {
    font-family: leJeuneDeck;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
    font-size: 28px;
    font-style: normal;
    line-height: 34px;
    text-transform: capitalize;
  }
  .badge-text {
    font-family: leJeuneDeck;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  @media screen and (min-width: 1024px) {
    .sub-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
   
  }

  .sub-text {
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
  }
  .intro-text{
    font-size: 16px;
    line-height: 30px;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
  }
  .sub-img {
    display: none;
  }
  .display-img{
    display: none;
  }
  .pic-div {
    margin-bottom: 24px;
    // scroll-snap-type: x mandatory;
    // @media screen and (max-width: 992px){
    //   scroll-padding-left: 20px;
    //   scroll-padding-right: 20px;
    // }
    @media screen and (min-width: 992px){
      margin-bottom: 35px;
    }
    @media screen and (min-width: 351px) and (max-width: 392px){
      width: 119.5% !important;
    }
    @media screen and (min-width: 393px) and (max-width: 413px){
      width: 118% !important;
    }
    @media screen and (min-width:414px) and (max-width:444px){
      width: 116.5% !important;
    }
    @media screen and (min-width:445px) and (max-width:489px){
      width: 115% !important;
    }
    @media screen and (min-width:490px) and (max-width:527px){
      width: 113.5% !important;
    }
    @media screen and (max-width: 350px){
      width: 113% !important;
    }
  }
  // @media screen and (max-width: 992px){
  //   .scroll-snap{
  //     scroll-snap-align:start;
  //   }
  // }
  /* -----phone (portrait)----- */
  @media screen and (max-width: 576px) {
    .main-img {
      padding: 0;
      display: none;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: 112.5%;
      // width:107.5%;
      margin-left: -32px;
    }
    .spd-img{
      height: 235px !important;
      width: 235px !important;
    }
    // .video-sm {
    //   height: 220px !important;
    //   width: 178px !important;
    // }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .productdetails-div{
      margin:auto
    }  
    .sub-img {
      display: block;
      // margin-left: 2px;
    }
    .display-img{
      display: block;
      // height: 213px;
      // width: 213px;
    }
    }
 
  /* ----iPads (portrait) ----*/
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .main-img {
      padding: 0;
      display: none;
    }
    .video-sm {
      margin-top: 6px;
    margin-left: -16px;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: auto;
      margin-left: 2px;
    }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .sub-img {
      display: block;
      width: 320px;
    }
    .sp-img{
      width: 320px;
      padding-left: 16px;
    }
    .image-size{
      width: 300px;
    }
    .display-img{
      display: block;
      width: 320px;
      // height: 272px;

      // width: 272px;
    }
    .main-section .para-main {
      font-size: 22px;
      line-height: 26px;
      padding-bottom: 0px;
    }
    .productdetails-div{
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 575px) {
    .sp-img{
      width: 300px;
      padding-left: 16px;
    }
    .sub-img{
      width: 300px;
     
    }
    .display-img{
      width: 300px;
    }
  }
  @media screen and (max-width: 576px) {
    .sp-img{
      width: 320px;
     
    }
    .sub-img{
      width: 320px;
      margin-left: 4px;
    }
    .image-size{
      width: 300px;
    }
    .display-img{
      width: 320px;
      // margin-left: -5px;
      margin-left: 3px;
    }
   
  }
 
  //---- phone (landscape)-----
  @media screen and (min-width: 577px) and (max-width: 767px) {
    .main-img {
      padding: 0;
      display: none;
    }
    .sub-img {
      display: block;
      // margin-left: 2px;
      width: 320px;
      margin-left: 2px;
    }
    .sp-img{
      width: 320px;
    }
    .display-img{
      display: block;
      width: 320px;
      margin-left: -5px;
    }
    .pic-div {
      flex-wrap: nowrap;
      height: auto;
      overflow-x: auto;
      width: 105%;
      margin-left: -20px;
    }
    .pic-div::-webkit-scrollbar {
      display: none;
    }
    .spd-img{
      height: 235px !important;
      width: 335px !important;
    }
    .productdetails-div{
      margin:auto;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 959px)
  {
    .sp-img{
      padding-left:16px !important;
     }
  }
  @media screen and (max-width: 959px){
  .product-heading {
      font-size: 20px;
      line-height: 30px;
    }
    .sub-text {
      font-size: 13px;
      line-height: 22px;
    }
    .intro-text{
      font-size: 13px;
      line-height: 22px;
    }
    .main-section .para-main {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 16px;
    }
  }
  @media screen and (max-width: 576px){
    .intro-text{
      margin-left: -8px;
    }
    .left-margin{
      margin-left: -8px; 
    }
  }
  .sp-divider{
    margin-bottom: 40px;
    margin-top: 40px;
    @media screen and (max-width: 959px){
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  .sp-img{
   padding-left:16px !important;
  }
  .show_truncate {
    overflow: visible;
    display: block;
    white-space: normal;
    height:auto;
    // cursor: pointer;
  }
  .branding-conclusion{
    margin-left: -10px;
    margin-top: 40px;
      @media screen and (max-width:959px) {
        margin-top: 30px;
      }
  }
  .margin-right-20{
    margin-right: 20px;
  }
  .grid-me-20{
    @media screen and (max-width:576px){
        // padding-right: 20px;
        margin-right: 20px !important;
    }
    @media screen and (min-width: 577px) and (max-width: 767px){
      // padding-right: 20px;
      margin-right: 20px !important;
    }
 
    @media screen and (min-width:768px)and (max-width:959px){
      // padding-right: 20px;
      margin-right: 20px !important;
    }
  }
  .mt{
    margin-top: 40px;
    @media screen and (max-width:959px){
      margin-top: 30px;
    }
  }
  @media screen and (max-width:959px){
    .mb-30{
      margin-bottom: 30px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .pdp_lg_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .pdp_xl_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1400px) {
    .pdp_xxl_cursor{
      cursor: pointer;
    }
}
.pdp_video_container {
  @media screen and (min-width: 992px) and (max-width: 1199px){                           /* LG breakpoint  */
   height: 486px;
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {                        /* XL breakpoint  */
    height: 580px;
  }
  @media screen and (min-width: 1400px){                                                 /* XXL breakpoint  */
    height: 675px;
  }
}  
}
 