.wrapper{
    width: 500px;
    height: auto;
    margin-bottom: 40px;
    margin-top: -22px;
    @media screen and (max-width: 959px){
        margin-bottom: 30px;
        margin-top: -14px;
    }
    @media screen and (max-width: 540px){
        width: 100%;
    }
 
    &_likeAndDislike_msg{
        width: fit-content;
        background-color: transparent;
        border: 1px solid #C6C8CC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 8px 16px 8px 16px;
        // padding: 4px 15px 11px 15px;
        @media screen and (max-width: 959px){
            padding: 6px 16px 6px 16px;
            // padding: 1px 15px 10px 16px;
        }
    }
 
    &_spanMsg{
        font-family: foundersGroteskTextRegular;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #595A5A;
        padding-right: 16px;
        // @media screen and (max-width: 959px){
        //     padding-right: 20px;  
        // }
    }
 
    &_likeAndDislike{
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
 
    &_box{
        // width: 64px;
        // height: 21px;
        gap: 10px;
        height: 16px;
        margin-bottom: 3px;
        @media screen and (max-width: 959px){
            // width: 60px;
            // height: 21px;
            // height: 17px;
            // margin-bottom: 2px;
        }      
      }
 
      .point{
        cursor: pointer;
        width: 14px;
        height: 14px;
        @media screen and (max-width: 959px){
            width: 12px;
            height: 12px;
        }
      }
 
    &_greetingTextBox{
        margin-top: 16px;
        width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: 540px){
            width: 100%;
        }
 
        &_inputBox{
            width: 500px !important;  
            padding: 12px 30px 12px 16px;  
            border-radius: 5px;
            border: 1px solid #C6C8CC;
            color:#595A5A;
            background-color: transparent;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.25px;
            font-family: foundersGroteskTextRegular;
           }
 
 
           .cross_btn{
            position: absolute;
            right:0;
           display: flex;
            // padding:2px 5px 2px 5px;
           }
 
           button.cross_btn{
            border: none !important;
            background-color: transparent !important;
           }
    }
   
}
 
 
// =====================================================================================================================================
//        =============================================      MODAL CSS       ===============================================
// =====================================================================================================================================
 
.modal{
    // display: flex !important;
    justify-content: center !important;
    align-items: center !important;  
   
}
 
//desktop///**********960-1440
    .modal-content{
        padding: 0px 0px 30px 0px;
        background-color: #FFFFFF !important;
        border-radius: 10px !important;  
                 
    }
 
    .modal-content_inner{
        // width:500px !important;
        // height: 277px;
        padding: 0px 30px 0px 30px;
        // background-color: #FFFFFF;
    }
 
    @media screen  and (min-width:960px){
    .modal-content{
        width:500px !important;  
      }
    }  
 
    @media screen  and  (min-width:577px) and (max-width:959){
        .modal-content_inner{
             width:500px !important;
        }
    }
 
 //mobile 0-959**********///
//     @media screen  and  (max-width:959px){
//         .modal-content{
//             width:375px !important;
//             height: 397px !important;
//             top:435px !important;
//             // left:470px !important;              
//             // padding: 10px 10px 0px 0px;  
//             // position: absolute !important;    
//             // bottom: 0 !;                
//         }
 
//         .modal-content_inner{
//             width:375px;
//             height: 323px;
//             padding: 0px 0px 0px 0px;
//         }
//  }
 
        @media screen  and  (max-width:576px){
            .modal{
                align-items: end !important;
                // width: 100% !important;
                --bs-modal-width: 100% !important;
            }
            .modal-dialog{
                width: 100%;
                margin: 0;
            }
        }
 
 
 
.modal-header{
    border-bottom: none !important;
    height:44px !important;
    padding: 15px 0px 15px 15px !important;
    gap:10px !important;
    justify-content: end;
 
    .closebtn{
        background: none !important;
        border: none !important;
        margin-right: -5% !important;
        margin-bottom: 5px;
        @media screen  and  (max-width:576px){
            margin-right: -4% !important;  
            padding: 0 !important;
        }
    }
 
    .close_icon{
        width: 14px !important;
        height: 14px !important;
    }
   
}
 
.modal-body{
    // width:440px !important;
    @media screen  and (min-width:960px){
        width:440px !important;
    }
   
    // height:205px;
    gap:20px;
 
    &__title{
        height:22px;
    }
 
    &_headingText{
        font-family: leJeuneDeck;
        font-weight: 400;
        font-size: 18px;
        line-height: 21.6px;
        color: #041E3A;
    }
 
    &_suggestedFeedback{
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap:10px;
        // height:163px;
 
        .feedbackMsg{
           color:#041E3A;
           background-color: white;
           border: 1px solid #9D9FA3;  
           font-family: foundersGroteskTextRegular;      
           font-weight:400;
           font-size: 12px;
           line-height: 14px;
           border-radius: 5px;
           padding:10px 12px 10px 12px;
            @media screen and (min-width:960px){
                &:hover{
                    background-color: #F2F3F5 !important;
                    border: 1px solid #041E3A !important;
                }
            }
        }
        .feedback_optional_box{
            width:440px;
            padding:14px 14px 20px 14px;
            font-family: foundersGroteskTextRegular;
            color: #041E3A;
            border: 1px solid #C6C8CC;
            border-radius: 5px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.25px;
            @media screen and (max-width: 576px){
                width: 100%;
            }
        }
        .feedback_optional_box:focus-visible{
            outline: none !important;
        }
    }
 
    // @media screen  and  (max-width:959px){
    //     width:315px;
    //     height:251px;
    // }
 
 
}
 
  input.feedbackMsg.selected{
    background-color: #F2F3F5 !important;
    border: 1px solid #041E3A !important;
   
 }
 
//  textarea.feedback_optional_box.selected{
//     background-color:#E5E4E2 !important;
//     border: 1px solid  #041E3A !important;
//  }
 
// textarea.feedback_optional:focus {
//     box-shadow: none;
//   }
 
.modal{
    --bs-modal-padding: 0rem !important;
    // --bs-modal-width: 100% !important;
}
 
.feedback_optional_box::placeholder {
    color: #6D6F73;
    opacity: 1;
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.25px;
}
 
.submitBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px 0 0 0;
   
}
 
.btnSize{
    width:100%;
    font-family: foundersGroteskTextMedium;
    line-height: 14px;
    font-size: 14px;
    padding: 19px 26px 19px 26px;
    border-radius: 5px;
}
#btnStyle {
    outline: 0 !important;
    box-shadow: none !important;
 
    // &:focus {
    //     outline: 0 !important;
    //     background-color: #041E3A;
    //     color: white;
    // }
}
 
.normalbtnClr{
    color: white;
    background-color: #C6CBCC !important;
    border: none !important;
}
 
.activeBtnColor{    
    background-color: #041E3A;
    color: white;
 
    &:focus {
        outline: 0 !important;
        background-color: #041E3A;
        color: white;
    }
 
    &:hover{
        background-color: #041E3A !important;
        color: white;
    }
}
 
#btnStyle:focus {
    outline: 0 !important;
}
 
 
#btnStyle:focus {
    outline: 0 !important;
    background-color: #041E3A;
    color: white;
}
 
button.btnSize:hover{
    background-color: #C6CBCC;
    color: white;
}
 
#myModal {
    backdrop-filter: blur(0.5px);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
  }
.feedback{
    background: none;
    border: none;
    align-self: center;
    // transform: translateY(-5%);    // for windows
    transform: translateY(5%);      // for mac
    padding-top: 3px;
}