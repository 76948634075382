.brandingcss{
    // height:320px;
    // width:320px;
    img {
      width: 100%;
      height: 100%;
    }
    // @media screen and (max-width: 335px) {
    //   height: 250px;
    //   width: 250px;
    // }   
    // @media screen and (min-width: 336px) and (max-width: 576px) {
    //   height: 280px;
    //   width: 282px;
    // }   
    @media screen and (min-width: 960px){
      max-width: 300px;
    }
  }
  .singleCatItem {                                  //not used
    padding: 70px 112px 70px 112px;
  
    .badge-text {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .badge-style {
    width: 150px;
    height: 33px;
    padding: 8px 18px 7px 18px;
    border-radius: 100px;
    margin-top: 15px;
    @media screen and (max-width: 959px) {
      margin-top: 0px !important;
    }
  }
  .product-detail.truncate {
    color: var(--polo-primary, #041e3a);
    font-size: 16px !important;
    line-height: 30px !important;
    font-family: foundersGroteskTextRegular;
    font-style: normal;
    font-weight: 400;
    // margin-top: 15px;
    max-width: 600px !important;
    @media screen and (max-width: 959px) {
      font-size: 13px !important;
      line-height: 22px !important;
    }
  }
  .icon-gap {
    gap: 7px;
  }
  
  @media (max-width: 576px){
    .sc-div{
      margin-left: -18px !important
    }
  }
  .item-div {
    margin-top: 0px;
    margin-left: -0.5rem;
    @media (max-width: 576px){
      margin-left: -2rem; 
    }
    @media (min-width: 767px){
      margin-left: -1.9rem; 
    }
  }
  .product-header-title {
    font-size: 28px;
    line-height: 34px;
    font-family: leJeuneDeck; 
    // margin-top: 4px;
    color: #041E3A;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize; 
      @media screen and (max-width: 959px){
        font-size: 20px;
        line-height: 30px;
        margin-top: 2px;
      }
    @media screen and (min-width: 960px){
      max-width: 600px;
    }
  }
  .badge{
    margin-top: 24px;
    @media screen and (max-width: 959px){
      margin-top: 16px;
    }
  }
  .sub-section {                           //not used
    .heading-sub {
      font-family: Arial, Helvetica, sans-serif;
      color: var(--bs-para-color);
      font-weight: bold;
      font-size: 30px;
    }
    .para-sub {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 300;
      font-size: 18px;
      color: var(--bs-para-color);
    }
  }
  
  .sc_product_details {
    margin-top: 25px;
  }
  .sc-main {                                   
    // margin-top: 40px;
    @media screen and (min-width: 768px){
      margin-left: 9px;
    }
  }
  // .section_margin {
  //   margin-top: 3rem;
  // }
  .branding-div {
    padding-left: 32px;
    color: var(--polo-primary, #041e3a);
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 576px) {
    .main-section {
      .para-main {
        font-family: leJeuneDeck;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        color: var(--polo-primary, #041e3a);
      }
    }
    .branding-div {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media screen and (min-width: 960px) {
    .hr-divider{
      margin-top: 40px;
      // margin-bottom: 40px;
    }
    .truncate {
      // margin-top: 15px !important;
      margin-top: 10px !important;
      margin-bottom: 18px;
      overflow: hidden;
      // cursor: pointer;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .show-truncate-text {
      overflow: visible; 
      display: block;
      white-space: normal;
      height:auto;
    }
  
  }
  @media screen and (max-width: 959px) {
    .hr-divider{
      margin-top: 30px;
    }
    .truncate {
      overflow: hidden;
      // cursor: pointer;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      line-clamp: 5;
      -webkit-box-orient: vertical;
      font-size: 13px !important;
      line-height: 22px !important;      
      // margin-top: 10px !important;
      margin-top: 4px !important;
      margin-bottom: 18px !important;
      color: var(--polo-primary, #041e3a);
    }
    .show-truncate-text {
      overflow: visible; 
      display: block;
      white-space: normal;
      height:auto;
    }
  
    .product-cat-Text {
      color: var(--polo-primary, #041e3a);
      font-family: foundersGroteskTextMedium;
      font-size: 9px !important;
      margin-top: 10px !important;
      line-height: 16px !important;
      letter-spacing: 2px !important;
    }
    .main-section {
          .para-main {
            font-family: leJeuneDeck;
            font-size: 18px;
            font-style: normal;
            line-height: 24px;
            color: var(--polo-primary, #041e3a);
          }
        }
  }
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ FONT STYLE SINGLE CAT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  
  .product-cat-Text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    color: var(--polo-primary, #041e3a);
    font-family: foundersGroteskTextMedium;
    margin-bottom: 10px;
  }
  
  .my-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sc-subdiv{
    // margin-top:-6%;
    // margin-top: -16px;
    padding-bottom:20px;
    @media screen and (max-width:959px){
      padding-bottom:30px;
      margin-top: 0px;
    }
    // @media screen and (max-width: 350px) {
      // margin-top:-33%; 
    // }
    // @media screen and (min-width: 351px) and (max-width: 400px) {
      // margin-top:-28%; 
    // }
    // @media screen and (min-width: 401px) and (max-width: 450px) {
      // margin-top:-24%; 
    // }
    // @media screen and (min-width: 451px) and (max-width: 500px) {
      // margin-top:-22%; 
    // }
    // @media screen and (min-width: 501px) and (max-width: 576px) {
      // margin-top:-20%; 
    // }
    // @media screen and (min-width: 577px) and (max-width: 620px){
      // margin-top:-16%; 
    // }
    // @media screen and (min-width: 621px) and (max-width: 690px){
      // margin-top:-14%; 
    // }
    // @media screen and (min-width: 691px) and (max-width: 767px){
      // margin-top:-12.5%; 
    // }
    // @media screen and (min-width: 768px) {
    //   display:flex;
    // }
    // @media screen and (min-width: 992px) and (max-width: 1199px){
      // margin-top:-6.5%; 
    // }
    // @media screen and (min-width: 1400px) {
      // margin-top:-6.1%; 
    // }
  }
  // .sc-img-div{
    // @media screen and (min-width: 768px) and (max-width:991px){                                /*  MD breakpoint  */
        // margin-top: -13.5%;
      // }
      // @media screen and (min-width: 992px) and (max-width: 1199px){                           /* LG breakpoint  */
        // margin-top: -9%; 
      // } 
      // @media screen and (min-width: 1200px) and (max-width: 1399px) {                        /* XL breakpoint  */
      // margin-top: -6%; 
      // }
      // @media screen and (min-width: 1400px){                                                 /* XXL breakpoint  */
      // margin-top: -2%;
      // }
    // }
    .product-list-btm-divider{
      margin-top: -36px;
      @media screen and (max-width: 959px){
        margin-top: 0px;
      }
    }
    .product-list-top-divider{
      margin-bottom: -16px;
      @media screen and (max-width: 959px){
        margin-bottom: 0px;
      }
    }
    
    @media screen and (min-width: 577px) and (max-width: 959px){
      .count1{
        margin-left: 7px;
      }
    }
    @media screen and (max-width: 576px){ 
    .sc-divider{
      width: 91%;
      margin-left: 30px;
     }
    }
    .product-count {
      font-family: Le Jeune Deck;
        margin-bottom: 20px;
        margin-top: 40px;
        font-size: 80px;
        font-style: normal;
        line-height: 80px;
        color: var(--polo-primary, #041e3a);
        @media screen and (max-width: 959px){
          font-family: leJeuneDeck;
          color: var(--polo-primary, #041e3a);
          font-size: 75px;
          font-style: normal;
          line-height: 85px;
          margin-top: 20px !important;
        }
      @media screen and (max-width: 576px) {
        margin-left: -2rem;
      }
    }
    .sc-conclusion{
      margin-top: 40px;
      @media screen and (max-width:959px){
        margin-top: 30px;
      }
    }
  @media screen and (max-width: 576px){
    .xs_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 767px){
    .sm_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 959px){
    .md_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 960px) and (max-width: 991px) {
    .mdcursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .xl_cursor{
      cursor: pointer;
    }
  }
  @media screen and (min-width: 1400px) {
    .xxl_cursor{
      cursor: pointer;
    }
    .intro_text{
      margin-left: -4% !important;
    }
  }
  .branding-img-placeholder{
    @media screen and (min-width: 992px) and (max-width: 1199px){                           /* LG breakpoint  */
      height: 370px;
     } 
     @media screen and (min-width: 1200px) {                                               /* XL breakpoint  */
       height: 402px;
     }
  }
  .editorial-img{
    min-width: 200px;
  }
    .dash {
      border-left: 1px dashed;
      border-image-source: url(../../../public/static/assets/vertical_dash.png);
      border-image-repeat: repeat;
      border-image-slice: 5;
    }
