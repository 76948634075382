.multiGridCatItem {
    .multiGridLayout {
      .gridDec {
        color: var(--Collection-Primary, #000);
        font-size: 28px;
        font-style: normal;
        line-height: 40px; 
        @media screen and (max-width: 959px) {
          color: var(--Polo-Primary, #041E3A);
          font-size: 18px;
          line-height: 24px; 
        }
      }
      .gridSubdesc {
        color: var(--Collection-Primary, #000);
        font-size: 18px;
        line-height: 36px;
        @media screen and (max-width: 959px) {
          color: var(--Polo-Primary, #041E3A);
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
     
    .primary-img-div::-webkit-scrollbar {
      display: none;
    }
    
    .product {
      .prod-type {
        color: var(--Polo-Primary, #041E3A);
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
        padding: 10px 0;
        @media screen and (max-width: 959px) { 
          font-size: 18px;
          line-height: 24px;
          padding: 5px 0;
        }
      }
      .prod-dec {
        color: var(--Polo-Primary, #041E3A);
        font-size: 18px;
        font-style: normal;
        line-height: 36px;
        @media screen and (max-width: 959px) {
        font-size: 14px;
        line-height: 24px;
        }
      }
    }
  
    .grid-text {
      color: var(--Polo-Primary, #041E3A);
      font-size: 18px;
      font-style: normal;
      line-height: 32px;
      @media screen and (max-width: 959px) {
      font-size: 14px;
      line-height: 24px;
      }
    }
    .slick-prev{
      left: 15px;
      z-index: 1;
      top: 39%;
      @media screen and (min-width: 1201px) and (max-width: 1400px){                    //XL breakpoint
        top: 37%;
      }
      @media screen and (min-width: 992px) and (max-width: 1200px){                     //LG breakpoint
        top: 35.5%;
      }
      @media screen and (min-width: 960px) and (max-width: 991px){                     
        top:28%;
      }
      @media screen and (min-width: 768px) and (max-width: 959px){                     
        top:32.3%;
      }
    } 
    .slick-next {
      right: 30px;
      top: 39%;
      @media screen and (min-width: 1201px) and (max-width: 1400px){                    //XL breakpoint
        top: 37%;
      }
      @media screen and (min-width: 992px) and (max-width: 1200px){                     //LG breakpoint
        top: 35.5%;
      }
      @media screen and (min-width: 960px) and (max-width: 991px){                     
        top:28%;
      }
      @media screen and (min-width: 768px) and (max-width: 959px){                     
        top:32.3%;
      }
    }
    .slick-prev.slick-disabled:before, 
    .slick-next.slick-disabled:before{
      display: none;
      // cursor: default !important;
   }
    [type=button]:not(:disabled), button:not(:disabled) {
     cursor: default !important;
    }
    .slick-prev:before {
      content: '<';
    }
    .slick-next:before {
      content: '>';
    }
    .slick-prev:before, .slick-next:before {
      font-family: foundersGroteskTextRegular;
      font-size: 20px;
      line-height: 1;
      opacity: 0.85;
      background: white;
      padding: 3px 8px 1px 9px;
      border-radius: 5px;
      color: #041E3A;
    }
    .show-carousel{
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    @media screen and (max-width: 767px){
      .primary-div{
        width: 232px !important;
      }
      .primary-img-div{
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
    @media screen and (max-width: 413px){
      .carousel-sm-div{
        // width: 112% !important;
        width: 111% !important;
      }
    }
    @media screen and (max-width: 576px){
    .carousel-sm-div{
      margin-left: -20px;
      width: 107.5%;
    }
   }
   @media screen and (min-width: 577px) and (max-width: 633px){
    .carousel-sm-div{
      margin-left: -20px;
      width: 107%;
    }
   }
   @media screen and (min-width: 634px) and (max-width: 729px){
      .carousel-sm-div{
        margin-left: -20px;
        width: 106%;
      }
    }
    @media screen and (min-width: 730px) and (max-width: 767px){
      .carousel-sm-div{
        margin-left: -20px;
        width: 105.7%;
      }
    }
    @media screen and (max-width: 767px){
      .grid-ms-20{
        margin-left: 20px;
      }
      .grid-me{
        margin-right: 18px !important;
      }
    }
    .grid-divider{
      margin-top: 40px;
      margin-bottom: 40px;
      @media screen and (max-width: 959px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  
  @media screen and (min-width: 375px) and (max-width: 667px) {
    .multiCatItem {                            //not used
      padding: 30px 20px 30px 20px;
    }
  }
  .active {
    opacity: 1;
  }
  
  @keyframes gridSlider {
    0%{
      opacity: 0;
    }
    40%{
      opacity: 0;
    }
    60%{
      opacity: 0;
    }
    80%{
      opacity: 0.7;
    }
    90%{
      opacity: 0.8;
    }
    100%{
      opacity: 1;
    }
  }

  .imgGrid1{
       animation: gridSlider 1s ease-in-out forwards;
  }

  .imgGrid2{
    animation: gridSlider 2s ease-in-out forwards;
}

.imgGrid3{
  animation: gridSlider 3s ease-in-out forwards;
}