.fadeInOut{                                            
    animation: fadeInOuts 2s ease-in-out forwards;
}
  
@keyframes fadeInOuts {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
  