input.form-control.form-focus:focus {
    box-shadow: none;
  }
  input.form-control.form-focus::placeholder {
    color: #6D6F73;
  }
  .chatbox {                                             
    text-align: center;
    width: 100%;
    border-radius: 13px;
    input {
      width: 100%;
    }
    .form-control {                                       
      padding: 12px 60px 12px 24px;
      font-family: leJeuneDeck;
      font-size: 18px;
      line-height:24px;
      border-radius: 13px;
      border: none;
      max-height: 170px;
      min-height: 60px;
      resize:none;
      position: absolute;
      bottom: -55px;
      top: auto;
      overflow: auto;
      @media screen and (max-width: 959px){
        padding: 15px 40px 15px 18px;
        border-radius: 10px;
        font-size: 14px;
        line-height:18px;
        min-height: 50px !important;
        max-height: 132px !important;
        bottom: -48px;
      }
    }
    .chat{
      padding-top: 16px !important;
      z-index: 3;
      @media screen and (max-width: 430px){
        padding-top: 17px !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px){
        // padding-top: 1.8% !important;
        padding-top: 2% !important;
      }
      @media screen and (min-width: 1200px) and (max-width: 1399px){
        padding-top: 1.6% !important;
      }
      @media screen and (min-width: 1400px){
        padding-top: 1.4% !important;
      }
    }
    .form-control:focus {
      box-shadow: none;
    }
    span {
      position: absolute;
      padding: 12px;
      right: 15px;
    }
  
    .send-arrow-position{                                   
      position: absolute;
      z-index: 3 !important;
      // right: 15px;
      // top: 1px;
      // @media screen and (max-width: 959px){
      //   transform: translateY(%) !important
      // }
    }
  
  .send-arrow-position.enabled{                           
    cursor: pointer;
    border:none
  }
  
  .send-arrow-position.disabled{                            
    border:none;
    background:none !important;
    cursor: default;
  }
   .textarea {
      width: 100%;
      height: 150px;
      padding: 12px 20px;
      box-sizing: border-box;
      border: 2px solid #ccc;
      border-radius: 4px;
      background-color: #f8f8f8;
      font-size: 16px;
      resize: none;
      background-color: yellow;
    }
  }

  .homepageresize {
    height: calc(100vh - 142px) !important;
    @media screen and (max-width: 959px){
      height: calc(100vh - 120px) !important;
    }
    // @media screen and (max-width: 430px){                                          // iphone height issue fix
    //   height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 17.8)) !important;
    // }
  }

  .homepage {                                                         
    height: calc(100vh - 102px);
    overflow: unset;
    @media screen and (max-width: 959px){
      height: calc(100vh - 85px);
    }
    // @media screen and (max-width: 430px){                                             // iphone height fix
    //   height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 12.5));
    //   @media screen and (max-height: 550px){
    //     height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 38.5));
    //   }
    // }
    .chatwindow {                                                    
      font-family: leJeuneDeck;
      .conversationlist {                                            
        height: calc(100vh - 84px);  
        @media screen and (max-width: 959px){
          height: calc(100vh - 67px);
        }
        // @media screen and (max-width: 430px){                                         // iphone height fix
        //   height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 10));
        //   @media screen and (max-height: 550px){
        //     height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 37));
        //   }
        // }
        .min-height{
          min-height: calc(100vh - 130px);
          // @media screen and (max-width: 430px){                                          // iphone height fix
          //   min-height: calc(calc(var(--vh, 1vh)* 100) - calc(var(--vh, 1vh)* 21));  
          // }
          @media screen and (max-width: 576px){
            min-height: calc(100vh - 114px);  
          }
          @media screen and (min-width: 577px) and (max-width: 768px){
          min-height: calc(100vh - 115px);
          } 
          @media screen and (min-width: 769px) and (max-width: 959px){
            min-height: calc(100vh - 115px);  
          }
        }
        @media screen and (max-width: 767px) {
          padding: 0px 20px;
        }
      }
      .history-conversationlist{
        height: calc(100vh - 0px);  
      }
    }
    .chatbox {
      background: linear-gradient(#e4d8c9, #ffffff);
      text-align: center;
      width: 100%;
      bottom: 0;
      input {
        width: 100%;
      }
      span {
        position: absolute;
        cursor: pointer;
        padding: 12px;
        right: 0;
      }
    }
  }
  .loader-div{
    margin-top: 0px;
    height: 25px;
    // background-color: #DFDFDF;
    position: sticky;
    @media screen and (max-width: 959px){
      height: 20px;
    }
  }
  .help-links{
    margin-top: -10px;
    overflow-x: auto !important;
    @media screen and (max-width: 959px){
      margin-top: -6px !important; 
    }
    @media screen and (max-width: 768px) {
      margin-left: -20px;
    }
    @media screen and (max-width: 444px) {
      width: 110%; 
    }
    @media screen and (min-width: 445px)and (max-width: 489px) {
      width: 109%;
    }
    @media screen and (min-width: 490px)and (max-width: 547px) {
      width: 108%;
    }
    @media screen and (min-width: 548px)and (max-width: 600px) {
      width: 107%;
    }
    @media screen and (min-width: 601px)and (max-width: 711px) {
      width: 106%;
    }
    @media screen and (min-width: 712px)and (max-width: 768px) {
      width: 105.5%;
    }
  }
  .help-links::-webkit-scrollbar {
    display: none !important;
  }
  .dots-loader {
    width: 7px;
    height: 7px;
    margin-right: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: dl 1s infinite linear alternate;
    @media screen and (max-width: 959px){
      width: 6.1px;
      height: 6.1px; 
    }
  }

  .send-arrow-position{
    position: absolute;
    right: 15px;
  }
  
  .send-arrow-position.enabled{
  cursor: pointer;
  border:none;
  background:none;
  }
  
  .send-arrow-position.disabled{
  border:none;
  background:none;
  }

  .loader-text{
    color: #6D6F73;
    font-family: foundersGroteskTextRegular;
    font-size: 12px;
    font-style: normal;
    line-height: 10px;
    margin-left: 21px;
    @media screen and (max-width: 959px){
      font-size: 10px;
      margin-left: 18px;
    }
  }
  .footer-container{
    @media screen and (max-width: 576px) { 
    padding: 0px 20px;
    }
  }
  .ms-20{
    margin-left: 0px;
    @media screen and (max-width: 767px) {
    margin-left: 20px !important;
    }
    @media screen and (min-width: 768px)and (max-width: 991px) {
      margin-left: 12px !important;
    }
  }

  .footer-tags {
    @media screen and (max-width: 767px) {
      overflow-x: auto;
      float: left;
      height: 54px;
    }
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    @media screen and (min-width: 992px) {
      margin-bottom: 8px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 4px;
    }
  }
  .footer-tags::-webkit-scrollbar {
    display: none;
  }
  .footer_btn {
    border: 2px;
    border-radius: 5px;
    white-space: nowrap;
    text-align: center;
    border: 1px solid rgba(4, 30, 58, 0.50);
    background: rgba(255, 255, 255, 0.00);
    // padding: 8px 14px 8px 14px;
    padding: 11px 14px 11px 14px;
    color: var(--polo-primary, #041e3a);
    font-family: foundersGroteskTextMedium;
    font-size: 12px;
    transition: background-color 0.3s ease-out;
    @media screen and (min-width: 959px){
      &:hover {
        border-radius: 5px;
        border: 1px solid rgba(4, 30, 58, 0.00);
        background: #FFF; 
      }   
    }
    @media screen and (max-width: 959px){
      padding: 8px 14px 8px 14px;
      margin: 0px 12px 16px 0px;
    }
    @media screen and (min-width:960px){
      white-space: pre-wrap !important; 
      margin: 0px 12px 12px 0px;
    }
    @media screen and (min-width:768px) and (max-width: 959px){
      white-space: pre-wrap !important; 
      margin: 0px 12px 12px 0px !important;
    }
  }
  .last-footer-btn{
    @media screen and (max-width: 576px) {
      margin: 0px 20px 16px 0px !important;
    }
    @media screen and (min-width: 577px) and (max-width: 767px) {
      margin: 0px 10px 16px 0px !important;
    }
  }
  
  @media screen and (max-width: 959px) {
    .footer_search{                                                   
     padding-bottom: 16px;
    }
    .arrow_long {
      height: 22px;
      width: 22px;
    }
    .footer-gradient{
      height: 20px;
    }
    .chat-arrow{
      transform: translateY(30%) !important;
      right: 12px !important;
    }
  }
  @media screen and (min-width: 960px){
    .footer_search{
      padding-bottom: 24px;
    }
    .footer-gradient{
      height: 24px;
    }
    .chat-arrow{
      transform: translateY(1.5%) !important;
      right: 12px !important;
      padding: 6px 14px !important;
    }
    .chat-arrow:hover{
        padding: 0.5px 14px 0.5px 14px !important;
        top: 6.4px !important;
        // transform: translateY(15.5%) !important;
        background: #F2F3F5 !important;
        border-radius: 10px;
        cursor: pointer;
    }
  }
  .footer_search {                                                 
    font-family: leJeuneDeck;
    // background: linear-gradient(0deg, #E1E1E1 0%, rgba(223, 223, 223, 0.00) 100%);
    @media screen and (max-width: 576px) {
      .chatbox span {
        padding-right: 2px;
      }
    }
    @media screen and (min-width: 577px) and (max-width: 767px) {
      .footer-tags {
        height: 60px;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x: auto;
      }
      .footer-tags::-webkit-scrollbar {
        display: none;
      }
    }   
  }

  .page-container {
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      // padding: 0px 20px;
      overflow-y: auto;
    }
  }
  .page-container::-webkit-scrollbar {
    display: none !important;
  }
  @media screen and (max-width: 767px) {
    .history-container{
      padding: 0px 20px; 
    }
  }
  
  #textarea_id:disabled {
    background-color: #ffffff;
  }
  .stop-icon{
    width: 10px;
    height: 10px;
    border-radius: 2px;
    @media screen and (max-width: 959px){
      width: 9px;
      height: 9px;
    }
  }
  .stop-text{
    font-size: 12px;
    line-height: 22px;
    transform: translateY(2.5%);
    @media screen and (max-width: 959px){
      font-size: 11px !important;
      margin-top: -1px;
      transform: translateY(4%);
    }
  }
  .stop_btn {
    background: #fff;
    border: 1px solid #041E3A59;
    border-radius: 6px;
    padding: 8px 16px;
    color: var(--polo-primary, #041e3a);
    gap:12px;
    position:sticky !important;
    @media screen and (max-width: 959px) {
      padding: 6px 12px;
      border-radius: 4px;
      }
      @media screen and (min-width: 960px) {
        &:hover{
          border: 1px solid #041e3a;
        }
        &:hover:active {                               // for safari
          border: 1px solid #041e3a;
        }
      }
    }

    .follow_btn{
      display: none !important;
    }

    @media screen and (max-width: 430px) {  
      //iphone fix
      .ios-chat-arrow {
        transform: translateY(50%) !important;
        right: 4px !important;
      }
      //screen responsiveness fix
      .res_homepageresize{
        height: calc(100vh - 120px) !important;
      }
      .res_homepage{
        height: calc(100vh - 85px) !important;
      }
      .chatwindow {
        .res_conversationlist{
          height: calc(100vh - 67px) !important;
        }
        .conversationlist {
          .res_min-height{
          min-height: calc(100vh - 126px) !important;  
          }
        }
      }
      .m-chat-arrow{
        transform: translateY(30%) !important;
        right: 12px !important;
      }
    }
  
  // textarea::-webkit-scrollbar {
  //   display:none;
  // }

  // .coversationlistscroll {
  //   overflow-y: hidden !important;
  // }
  
  // .conversationlist::-webkit-scrollbar-track {
  //   display: none;
  //   overflow: hidden !important;
  // } 

  // .conversationlist::-webkit-scrollbar {
  //   width: 0em;
  // }