.usersection_faqbeta {
      .faq-header{
        margin-top: 10px;
      }
      .faq-beta{
        margin-top: 32px;
        color: #041E3A;
        font-family: leJeuneDeck;
        font-size: 20px;
        font-style: normal;
        line-height: 18px;
      }
      .faq-title{
        font-family: foundersGroteskTextMedium;
        font-size: 12px;
        line-height: 18px;
        color: #041E3A;
       
      }
      .faq-content{
        font-family: foundersGroteskTextRegular;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.36px;
        color: #041E3A;
        margin-top: 11px;

      }
    }

      @media screen and (max-width: 576px) {
        .faq-beta{
          margin-top: 27px;
          font-size: 14px;
          line-height: 18px;
        }
    }