.header_transparent {
    position: sticky;
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 131.43%),linear-gradient(0deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.19) 100%),rgba(255, 255, 255, 0.01) !important;
    // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
    // backdrop-filter: blur(30px);
    background: none !important;
    box-shadow: none !important;
    backdrop-filter: none !important;
}

.header {
    font-family: leJeuneDeck;
    top: 0;
    position: fixed;
    width: 100%;
    // z-index: 30;
    z-index:1000;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(7.5px);
    .header_options {
      padding: 18px 30px;
      @media screen and (max-width:959px){
        padding: 18px 20px; 
      } 
    }    
    .header_logo {
      width: 220px;
      height: auto;
      img {
        width: 254.75px;
        height: 18px;
        @media screen and (max-width:959px){
        width: 165.33px;
        height: 12px;
        }
      }
      @media screen and (min-width: 280px) and (max-width:767px)
      {
        width: 175px ;
      }
  
    }
  
    .sidebar-body {
      background: #f5f5f2;
    }
    .menu-close-icon{
      background: none;
      border: none;
    }
    .usersection {
      color: #041e3a;
      font-size: 18px;
      font-weight: 400;
      margin-top: 6%;
      .username {
        margin-left: 14px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
      .policy {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 500;
      }
      .userterms {
        font-size: 10px;
        font-weight: 400;
        color: #6d6f73;
        a {
          color: #041e3a;
        }
      }
      
      .faq-div{
        margin-top: 32px;
        color: #041E3A;
        font-family: leJeuneDeck;
        font-size: 20px;
        font-style: normal;
        line-height: 18px;
      }
      .faq-title{
        font-family: foundersGroteskTextMedium;
        font-size: 12px;
        line-height: 18px;
        color: #041E3A;
       
      }
      .faq-content{
        font-family: foundersGroteskTextRegular;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.36px;
        color: #041E3A;
        margin-top: 11px;

      }
      .signout-div{
        margin-top: 32px;
        margin-bottom: 10px;
        color: #041E3A;
        font-family: leJeuneDeck;
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        cursor: pointer;
      }
  
      @media screen and (max-width: 576px) {
        .faq-div{
          margin-top: 27px;
          font-size: 14px;
          line-height: 18px;
        }
        .signout-div{
          margin-top: 27px;
          margin-bottom: 32px;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;
        }
        .policy {
          margin-top: 32px;
        }
        .username {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .offcanvas.offcanvas-end {
      height: 100vh;
    }
    @media screen and (max-width: 767px){
      .offcanvas.offcanvas-end {
        height: 100vh;
        width: 100%;
        background-clip: border-box !important;
        transform: translateY(100%) !important;
      }
      .offcanvas.show:not(.hiding), .offcanvas.showing {
        transform: none !important;
      }
    }
  }

  .close-chat-btn{
    border-radius: 20px;
    padding: 9px 20px 8px 20px;
    gap: 10px;
    font-family: foundersGroteskTextMedium; 
    font-size: 10px;
    line-height: 12.7px;
    letter-spacing: 1px;
    text-align: center;
    border: 1px solid #041E3A;
    color: #041E3A;
    text-transform: uppercase;
    background: none !important;
    &:active{
      color: #FFFFFF;
      background: #041E3A !important;
    }
  }

//desktop
@media screen  and (min-width:960px) {
  .icon-dist{
    margin-right: 20px;
    display: none;                                      // hide shopping bag icon
  }
  .cart-icon{
    height:21px;
    width: 17.9px;
    align-items: center;
  
  }
  .hamburger-menu-icon{
      height:21px;
      width: 17.9px;
     align-items: center;
     

  }
 
}
//mobile
@media screen  and (max-width:959px) {
  .menu-close-icon{
    margin-top: 2.5px !important;
    margin-right: 3px;
  }
  .icon-dist{
    margin-right:20px;
    display: none;                                    // hide shopping bag icon
  }
  .cart-icon{
    height:16px;
    width: 16px;
    align-items: center;
  
  }
  .hamburger-menu-icon{
     height: 16px;
     width: 16px;
     align-items: center;
     

  }
  .profile-icon{
   width: 16px;
   height: 16px; 
  }
  
}

// loader css 
.spinner-overlay{
  position: fixed;
  top:50%;
  left:50%;
  height: 100vh;
  width:auto;
  display: flex;
  justify-content: center;
  align-items: center;  
  z-index: 9999;
  // color: #041E3A;
}
.custom-spinner{
  width: 5rem;
  height: 5rem;
  border-width: 0.3rem;
  border-style: solid;
  border-color: #041E3A #041E3A gray gray ;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0%{
    transform: rotate(90deg);
 
 }
  100%{
    transform: rotate(360deg);
 
  }
 
}
.faq-btn{
  border: none;
  background: none;
}
.disablefaq{
  opacity: 0.5;
}
.faq_fade{                                            
    animation: faqfade 1s ease-in-out forwards;
  }
  
  @keyframes faqfade {
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
  }
