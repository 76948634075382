@media screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
.mb{  
  margin-bottom: 40px;
  @media screen and (max-width: 959px) {                    
    margin-bottom: 30px;
  }
}
  
.common_btn {
    border: 0;
    background: #041e3a;
    padding: 8px 25px;

    font-size: 14px;
    color: #ffffff;
    border-radius: 25px;
}

.main-section {
  .para-main {
    font-family: foundersGroteskTextRegular;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    font-style: normal;
    color: $bs-head-color;
  }
  .heading-main {                                             //not used
    // font-family: "Lato", sans-serif;
    color: var(--bs-para-color);
  }
}
@media screen and (max-width: 959px) {
  .main-section {
    .para-main {
      // font-family: leJeuneDeck;
      font-size: 13px;
      font-style: normal;
      line-height: 22px;
      color: var(--polo-primary, #041e3a);
    }
  }
}

.divider_margin {
  margin-bottom: 2px;
}

/**** common tooltip ******/
[data-tooltip] {
  display: inline-block;
  position: relative;
 
}
/* Tooltip styling */
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background:  #212121;
  color: #fff;
  padding: 10px 13px 8px 12px;
  gap: 10px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  width: 180px;
  font-weight: 100;
  font-family: foundersGroteskTextRegular;
}
/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before
 {
    left: 100%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
/* Dynamic vertical centering */
[data-tooltip-position="top"]:before {
  // bottom: 180%;
  bottom: 130%;

}
/* Tooltip arrow styling/placement */
[data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  
  height: 20;
  border-color: transparent;
  border-style: solid;
  margin-bottom: -5px;
}
/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position="top"]:after
 {
  left: 50%;
}
/* Dynamic vertical centering for the tooltip */
[data-tooltip-position="top"]:after {
  // bottom: 180%;
  bottom: 130%;
  border-width: 6px 6px 0;
  border-top-color:#212121   ;
}
/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

//common divider animation 
.dividerAnimate {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}
.dividerAnimate1 {
  animation: fadeIn ease 7s;
  -webkit-animation: fadeIn ease 7s;
  -moz-animation: fadeIn ease 7s;
  -o-animation: fadeIn ease 7s;
  -ms-animation: fadeIn ease 7s;
}

.chat_options {                                             
  .chat_msg {
    width: fit-content;
    max-width: 800px;
    background: #fbfbf9;
    font-size: 18px;
    line-height: 24px;
    font-family: leJeuneDeck;
    font-weight: 400;
    color: var(--polo-primary, #041e3a);
    margin-bottom: 30px;
    border-radius: 10px;
    // padding: 12px 20px 12px 12px;
    padding: 12px 20px;
    box-shadow: 0px 5px 10px 0px #0000000d;
    @media screen and (max-width: 959px) {
      width: fit-content;
      font-size: 13.5px;
      max-width: 450px;
      line-height: 18px;
      margin-right: 1px !important;
      padding: 12px 16px;
    }
    // @media screen and (min-width: 425px) and (max-width: 434px){    //chat bubble width adjusted for mobile
    //   max-width: 91% !important;
    // }
    // @media screen and (min-width: 417px) and (max-width: 424px){
    //   max-width: 93% !important;
    // }
    // @media screen and (min-width: 409px) and (max-width: 416px){
    //   max-width: 95% !important;
    // }
    // @media screen and (min-width: 395px) and (max-width: 408px){
    //   max-width: 99% !important;
    // }
    // @media screen and (min-width: 380px) and (max-width: 394px){
    //   max-width: 91% !important;
    // }
    // @media screen and (max-width: 379px){
    //   max-width: 93.5% !important;
    // }
    @media screen and (min-width: 960px) {
    margin-bottom: 40px;
    }
    span.badge-text {
      margin-top: 1%;
    }
    .user_bubble {
      color: #6d6f73;
      font-size: 10px;
      line-height: normal;
      padding: 12px;
      border-radius: 3px;
      background: var(--collection-warm-grey-4, #e1e1dc);
      font-family: foundersGroteskMonoMedium;
      margin-right: 12px;
      margin-left: 2px;
      @media screen and (max-width: 959px) {
        margin-left: 0px;
        padding: 7px;
      }
      
    }
  }
  .chat_bubble_spacing{ 
    height: 60px !important;
    @media screen and (max-width: 959px) {
      height: 52px !important;
    }
  }
  .response_div_spacing{
    height: 62px !important;
    @media screen and (max-width: 959px) {
      height: 50px !important;
    }
  }
  
  @keyframes text_animation {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}

.product-detail {                                      
  font-size: 16px !important;
  line-height: 30px !important;
  font-family: foundersGroteskTextRegular;
  font-weight: 400;
  color: #041E3A;
  font-style: normal;
  @media screen and (max-width: 959px) {
    font-size: 13px;
    line-height: 22px;
  }
}

.send-arrow-position{
  position: absolute;
  right: 15px;
}

.send-arrow-position.enabled{
cursor: pointer;
border:none;
background:none;
}

.send-arrow-position.disabled{
border:none;
background:none;
}

.active {
  opacity: 1;
}

.horizontal_dash {
  border-top: 1px dashed;
  border-image-source: url(../../../public/static/assets/horizontal_dash.png);
  border-image-repeat: repeat;
  border-image-slice: 5;
}

.utility_icons{
  height: 13px;
  width: 13px;
  margin-right: 10px;
  @media screen and (max-width:991px){
    height: 11px;
    width: 11px;
    margin-right: 0px;
  }
}

.utility-btn {
  border: 1px solid rgba(4, 30, 58, 0.5);
  text-transform: uppercase;
  background: none;
  padding: 12px 22px 11px 20px;
  margin-right: 11px;
  border-radius: 100px;
  gap: 12px;
  font-size: 10px;
  line-height: 12.7px;
  letter-spacing: 1px;
  font-family: foundersGroteskTextMedium;
  color: var(--polo-primary, #041e3a);
  transition: background-color 0.3s ease-out;
  @media screen and (max-width: 991px){
    padding: 10px 12px 10px 12px; 
  }
  @media screen and (max-width: 991px){
   background: #FFFFFF;
   border: none !important;
  }
  @media screen and (min-width:768px) and (max-width: 991px){
    transform: scale(1.1);
    margin-right: 13px;
  }
}
.utility-btn-hover{
  @media screen and (min-width: 960px){
    &:hover {
      border: 1px solid #FFFFFF;
      background: #FFF;
    }   
  }
  @media screen and (max-width: 959px){
    &:active {
      background: #FFF; 
      border: 1px solid #FFFFFF;
    }   
  }
}
.shop_icon{
 margin-bottom: 0px;
 margin-top: -2px;
 margin-right:5px;
 height: 15px;
  width: 15px;
 @media screen and (max-width: 991px){
  margin-right: 0px;
  height: 13px;
  width: 13px;
 }
}
.styles_icon{
  margin-right:7px;
  margin-top: -1px;
  margin-bottom: 1px;
  @media screen and (max-width: 991px){
    margin-right: 0px;
   }
}

// react player play/pause button

.player-container {
  position: relative;
  // width: 100%;
  // height: 70vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
}

.play-pause-overlay {
  bottom: 11%;
  margin-left: 13px;
  position: absolute;
  display: flex;
  color: #041e3a;
  cursor: pointer;
  @media screen and (min-width:1400px) {
    bottom: 3.5%;
    margin-left: 1.5%;
  }
  @media screen and (min-width:1024px) and (max-width:1199px){
    bottom: 18%;
    margin-left: 2.5%;
  }
  @media screen and (min-width:992px) and (max-width:1023px){
    bottom: 8%;
    margin-left: 2.5%;
  } 
  @media screen and (min-width:768px) and (max-width:991px){
    margin-left: 2%;
    bottom: 5.5%;
  } 
  @media screen and (min-width:577px) and (max-width:767px) {
    margin-left: 2.5%;
    bottom: 6.5%;
  }
  @media screen and (max-width:576px) {
    margin-left: 2%;
    bottom: 6%;
  }

}
.sp-play-pause-overlay{
  padding-left: 12px;
  padding-bottom: 12px;
  position: absolute;
  display: flex;
  color: #041e3a;
  cursor: pointer;
  @media screen and (max-width:991px){
    bottom: 2%;
    left: 6%;
  }
  @media screen and (min-width:992px){
    bottom: 0;
    left: 0;
  }
}
 
.play-pause {
  width: 27px;
}
@keyframes fadeInPromts {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
 
  90% {
    opacity: 0;
  }
 
  100% {
    opacity: 1;
  }
}
 
.promtsFade {
  animation: fadeInPromts ease-in;
}
.count1{
  margin-top: 0px !important;
}
.divider{
  margin-top: 40px;
  margin-bottom: 40px;
  @media screen and (max-width:959px){
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
p {
  margin-bottom: 0 !important;
}
.text_res{
  margin-bottom: 16px !important;
  @media screen and (max-width: 959px){
    margin-bottom: 14px !important;
  }
  p {
      margin-bottom: 24px !important;
    @media screen and (max-width: 959px){
      margin-bottom: 16px !important;
    }
  }
}