// .sc-main {                                   
    // margin-top: 40px;
    // margin-left: 9px;
// }

@media screen and (min-width: 1200px) {
    .i-pad {
      margin-left: -1.1rem;
    }
  }
  @media screen and (min-width: 1400px) {
    .i-pad {
      margin-left: -3.1rem;
    }
}
.branding-divider{
  margin-top: 30px;
  margin-bottom: 40px;
  @media screen and (max-width: 959px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.branding-video-css{
  height: 100% !important;
@media screen and (min-width: 1024px) {
    height: 430px !important;
  }
}
.branding-video-div{
  margin-top: 30px;
  margin-bottom: 24px;
  @media screen and (max-width: 959px) {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}
.branding-mb{
  margin-bottom: 24px !important;
  @media screen and (max-width: 959px){
    margin-bottom: 16px !important;
  } 
}

// numbered listing in text response
// .text_res{
//   ol li p strong{
//     font-size: 20px;
//     line-height: 30px;
//     font-family: leJeuneDeck; 
//   }
//   ol li::marker{
//     font-size: 20px;
//     line-height: 30px;
//     font-family: leJeuneDeck; 
//   }
//   ul li::marker{
//     font-size: 16px;
//     line-height: 30px;
//     font-family: foundersGroteskTextRegular;
//     font-style: normal;
//     font-weight: 400;
//     @media screen and (max-width: 959px) {
//       font-size: 13px;
//       line-height: 22px;
//     }
//   }
// }