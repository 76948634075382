.useronboard {
    background-image: url(../../../public/static/assets/UpdatedLandingDesktop.jpg);
    animation-duration: 4s;
    background-repeat: no-repeat;
    background-position: top center !important;
    background-size: cover;
    min-height: 100%;
    background-attachment:fixed;
    min-width: 100%;
    animation: userOnboardBackground 3s ease-in forwards;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 767px){
      background-image: url(../../../public/static/assets/Landing-mobile-bg.jpg);
      background-size: cover !important;
      background-position: center top;
      -webkit-background-size:cover;
    }
    @media screen and (max-width: 400px){
      overflow-x: hidden !important;
      // background-size: auto;
    }
  
    .closebtn {
      margin-left: 90%;
      // margin-top: 10px;
      margin-top: 4px;
      margin-bottom: 6px;
      border: none;
      background:none;
      @media screen and (max-width: 959px){
        margin-top: 4px;
        margin-bottom: 0px;
        padding: 5px 6px 3px 6px !important;
      }
    }
    .pb_4{
      padding-bottom: 4px;
    }
    
    .msg::placeholder {
      color: #9D9FA3;
    }
    .logo {
      text-align: center;
      color: white;
      font-size: 80px;
      font-family: leJeuneDeck;
      // margin-bottom: -12px;
      // margin-top: -0.5rem;
      // margin-left: 2rem;
      opacity: 100%;
    }
    .send-arrow-position {  
      right: 15px !important;
    }
  
    //common breakpoint mobile//
    @media screen and (max-width:576px){
      .logo{
        height: 105px !important;
        width: 269px !important;
      }
      .logo-img{
        height: 105px !important;
        width: 269px !important;
        max-width: none;
      }
      // .Corresel { 
        // bottom: -1.8rem !important; 
        // position: fixed !important;
        // @media screen and (max-height: 705px){
        //   // top: 29rem;
        //   top: 31rem;
        //   margin-top: 30px !important;
        // }
      // }
      .chatbox.box-msg{
        top:48.88% ;
        position: fixed !important;
      }
    }
    // @media screen  and (max-height:820px){     
    //   overflow: auto;
    //   .Corresel{
    //      top:29rem;
     
    //     position:fixed !important;
    //   }
    // }
    @media screen and (min-width:577px) and  (max-width:959px){
      .logo{                           // logo scaling
        height: auto !important;
        width: 50% !important;
        left:27% !important; 
      }
   }
  // @media screen  and (max-height:500px){  
  //   overflow: auto;
  //   .logo{
  //     top:5.7rem !important;
  //     position:fixed;
  //   }
  //   .chatbox.box-msg{
  //         margin: 0 auto !important;
  //         top:14.5rem !important;
  //   }
  // }
  @media screen  and (min-width:960px) and (max-height:780px){  
    overflow: auto;
    .logo{
      top:12rem !important;
      position:fixed;
    }
    .chatbox.box-msg{
      margin: 0 auto !important;
      top: 23rem !important;
    }
  }
  @media screen  and (max-width:959px) and (max-height:780px){  
    overflow: auto;
    .logo{
      top:12.7rem !important;
      position:fixed;
    }
    .chatbox.box-msg{
      margin: 0 auto !important;
      top: 21.8rem !important;
    }
  }
  @media screen  and (max-width:576px) and (min-height:781px) {  
    overflow: auto;
    .logo{
      top:12.7rem !important;
      position:fixed;
    }
    .chatbox.box-msg{
      margin: 0 auto !important;
      top: 21.8rem !important;
    }
  }
  @media screen  and (max-width:576px) and (min-height:915px) {  
    overflow: auto;
    .logo{
      top:12.7rem !important;
      position:fixed;
    }
    .chatbox.box-msg{
      margin: 0 auto !important;
      top: 21.8rem !important;
    }
  }
  //iphone responsiveness
  @media screen and (max-width: 430px) and (max-height: 550px){
    .logo{
      top:8rem !important;
      position:fixed;
    }
    .chatbox.box-msg{
      margin: 0 auto !important;
      top: 16rem !important;
    }
  }
  @media screen  and (max-height:530px){
 .btn{
      top:17.6rem !important;
    }
    .Corresel{
      top:17.6rem !important;
    }
  }
  @media screen  and  (min-width:577px) and (max-width:959px) and (min-height: 914px){
    .logo{
      top: 26% !important;
    }
    .chatbox.box-msg {
      top: 42% !important;
    }
  }
  // mobile
    @media screen  and  (max-width:959px)
    {
      .logo{
        position: fixed !important;
        top:26% ;
        transition: transform 0.35s ease-in-out;
        // @media screen and (min-height: 914px){
        //   top: 26% !important;
        // }
      }
      .chatbox {
        position: fixed;
        transition: transform 0.35s ease-in-out;
      }
      .chatbox.box-msg
      {
        height: 50px;
        margin: 20px;
        width: 90% !important;
        margin-left: 20px;
        margin-right: 20px;
        position:fixed !important;
        border-radius: 15px;
        gap: 10px;
        // top:38% !important;
        top: 42%;
        // @media screen and (min-height: 914px){
        //   top: 42% !important;
        // }
      }
      .arrow-dist
       {
        position: absolute !important;
        // transform: translateY(140%) !important;
        top: 75% !important;
        padding: 1px 2px !important;
       }
      .msg
       {
        padding:  0px, 18px, 0px, 18px !important;
        height: 50px;
        margin: 20px !important ;
        font-size: 14px;
        line-height: 18px;
        padding-top: 176px;
        min-height: 50px !important;
        @media screen and (min-height: 914px){
          height: 50px !important;
        }
        
       }
      .arrow_long{
        height: 22px !important;
        width: 22px !important;

       }
      
      .btn{
        height: 32px !important; 
        width: 117px !important;
        font-size: 11px !important;
        position: fixed;
        // top: 27.5rem !important;
        // margin-top: 30px;
        top: calc(42% + 80px + 30px + 1rem) !important;
      }
      .close-icon{
        height: 16px !important;
        width: 16px !important;
      }
      .Corresel {
        position: fixed !important;
        bottom:40px;
        width:375px !important;
        height: 210px !important;
        margin: 30px;
        top:57.5% !important;
        margin-left: 6% !important;
      }
       .carol_content {
        padding: 42px 70px!important;
        padding-top: 2px !important;
        }
      
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
      position: fixed;
        
      }
      .send-arrow-position {
        right: 25px;
      }
      .Corresel .corouselslider {
        width: 14px;
        height:1.1px !important; 
    }
    @media screen  and (max-height:530px){
      .btn{
           top:21.7rem !important;
         }
    }
    @media screen  and (max-height:540px){
         .Corresel{
          top: 19.4rem !important;
         }
    }
    // @media screen  and (max-height:460px){  
    //   .logo{
    //     top:7.8rem !important;
    //   }
    // }
    .carol_content_1 {
      padding: 1px 55px !important;
    }
    .carol_content_3{
      padding: 1px 99px !important;
    }
  }
  
    //desktop//
    @media screen  and (min-width:960px),screen and (min-height: 914px)
  
    {
      .logo{
        // height:108.85px;
        width: 450px;
        position:absolute;
        top:calc(18.5% + 50px);
      }
    
       .chatbox.box-msg{
        position: absolute;
        width: 911px;
        top:47% ;     
        // top: 27.5rem;    
       }
       .arrow-dist
       {
        position: absolute !important;
        // transform: translateY(30%);
        // padding-top: 1.3%;
        padding-top: 0.7%;

       }
       .arrow-dist:hover{
        padding: 1px 14px 0.5px 14px !important;
        right: 7px !important;
        top: 6px !important;
        background: #F2F3F5 !important;
        border-radius: 10px;
        cursor: pointer;
        }
      .msg{
        height: 60px;
        font-size: 18px !important;
        min-height: 50px !important;
        max-height: 170px !important;
        padding-top: 0.9% !important;
      }
      .arrow_long { 
        height: 36px;
        width: 36px;
      }
      .btn{
        height: 32px;
        width: 117px;
        font-size: 11px;
        // top: 31rem;  
        top: 53%; 
        margin-top: 30px; 
        position: fixed;
      }
      .close-icon{
        height: 10px;
        width: 10px;
      }
      .chatbox span {
       right: 12px;
      }
      .Corresel { 
        height: 198px;
        position: fixed;
        // bottom:22%;
        // top: 32.5rem !important;  
        top: 53.4%;
        margin-left: 2%;
        margin-top: 30px; 
        margin-bottom: -1rem !important;
        text-align: initial;
         
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
  
      margin-bottom:25px !important  ;
        
      }
      .send-arrow-position {
        right: 25px;
      }
      .Corresel .corouselslider {
        width: 13px;
        height:1.8px !important;
    }
     .carousel-control-next{
         right: 4.5% !important;
       }
       .carousel-control-prev {
         left: 4.5% !important;
       } 
       .carol_content_1{
        padding: 1px 44px;  
      }
      .carol_content_3{
        padding: 1px 87px !important;
      }
      .btn:hover{
        background: white ;
        color: #041E3A ;
      } 
    }
    
    //width of corousel 320 to 414
    @media screen and (min-width:320px) and (max-width:414px){
    
      .Corresel{
        width: 98.2% !important;
        top: auto !important;
        bottom: -1.5rem !important;
        position:fixed !important;
        margin-left: 7.6% !important;
        border-radius: 15px 15px 0px 0px !important;
        @media screen and (max-height: 550px){
          top: 19.4rem !important; 
        }
      }
      
    }
  @media screen and (max-height: 720px) {
    overflow:auto;
  }
    .msg {
      font-family: leJeuneDeck;
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
      padding: 12px 60px 12px 24px;
      @media screen and (max-width: 959px){
        padding: 15px 45px 15px 18px !important;
      }
    }
    
    .send-arrow-position {
      right: 25px;
    }
  
    .btn {
      border-radius: 20px;
      // background: transparent;
      color: white;
      font-family: foundersGroteskMonoRegular;
      line-height: 14px;
      display: inline-flex;
      flex-direction: column;
      text-transform: uppercase;
      padding: 10px 20px 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      position: fixed;
      transition: background-color 0.3s ease-out;
      border-radius: 20px;
      border: 1px solid var(--Global-White, #FFF);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), rgba(255, 255, 255, 0.01);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(30px);
    }
  
    .header_local {
      top: 0;
      width: 800px;
      height: auto;
    }
  
    .Corresel {
      background-color: white;
      width: 350px;
      height: 198px;
      border-radius: 15px;
      // margin-left: 2%;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .carousel-inner {
        height: 90%;
      }
      .carousel-item {
        height: 100%;
        text-align: center;
      }
      .carol_content {
        padding: 1px 60px;
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        width: 15px;
        height: 15px;
        margin-bottom: 25px;
        
      }
      .btn-close {
        margin-left: 88%;
        font-size: 10px;
        margin-top: 10px;
      }
      .common_btn {
        border: 0;
        background: #041e3a;
        padding: 2px 20px;
        line-height: 29px;
        font-size: 11px;
        color: #ffffff;
        border-radius: 20px;
        font-family: foundersGroteskMonoRegular;
        margin-top: 10px;
      }
      .heading1 {
        font-family: leJeuneDeck;
        // font-size: 50px;
        line-height: 24px;
        letter-spacing: 0px;
      }
      .heading2 {
        font-family: foundersGroteskTextRegular;
        line-height: 22px;
      }
      .commonheader {
        font-family: foundersGroteskTextMedium;
        line-height: 20px;
        letter-spacing: 2px;
        font-size: 10px;
        text-transform: uppercase;
      }
      .corouselslider{
        width: 14px;
        height: 1.1px;
      }
    }
    .Corresel:hover{
      background-color: #F2F3F5;
      border-radius: 15px;
      background: var(--Polo-Cool-Grey-5, #F2F3F5);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(2px);
    }
    
    .animation-msg{
      animation: blink 6s linear infinite;
      // animation-delay: 0.5ms;
    }
   
    .message-fade {                                          
      text-align: start;
      // position: fixed;
      top: 0.5px;
      padding-left: 2rem;
      font-size: 18px;
      line-height: 24px;
      color: #6D6F73;
      @media screen and (max-width:959px){
        font-size: 14px;
        line-height: 18px;
        top: 32px;
      }
      @media screen and (max-width:359px){
        padding-right: 25px !important;
        top: 23px !important;
      }
    }
  @keyframes blink {
    0% {
      opacity: 0;    
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80%{
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    // 95% {
    //   opacity: 0.5;
    // }
    100% {
      opacity: 0;
    }
  }
  
  
    .onboard_image {                                       //not used 
      // max width phone potrait 576px, ipad potrait 768px landscape 1024, monitor 1200px
      @media screen and (max-width: 576px) {
        img {
          width: 250px;
          margin-bottom: 10px;
        }
      }
    }
    .onboard_width {                                         //not used 
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: space-between;
      }
      text-align: center;
    }
    .common_btn {
        border: 0;
        background: #041e3a;
        padding: 8px 25px;
      
        font-size: 14px;
        color: #ffffff;
        border-radius: 25px;
      }
  }

  .logo.focused, .chatbox.focused {
    transform: translateY(-2vh);
  }

  .chatbox {                                             
    text-align: center;
    width: 100%;
    border-radius: 13px;
    input {
      width: 100%;
    }
    .form-control {                                      
      padding: 12px 70px 12px 24px;
      font-family: leJeuneDeck;
      font-size: 18px;
      line-height:24px;
      border-radius: 13px;
      border: none;
      max-height: 170px;
      min-height: 60px;
      resize:none;
      position: absolute;
      bottom: -55px;
      top: auto;
      overflow: auto;
      @media screen and (max-width: 959px){
        padding: 15px 60px 15px 18px;
        border-radius: 10px;
        font-size: 14px;
        line-height:18px;
        min-height: 50px !important;
        bottom: -48px;
      }
    }
    .chat{
      padding-top: 17px;
    }
    .form-control:focus {
      box-shadow: none;
    }
    span {
      position: absolute;
      padding: 12px;
      right: 15px;
    }
  
    .send-arrow-position{                                   
      position: absolute;
      right: 15px;
      top: 1px;
      @media screen and (max-width: 959px){
        // top: 4px;
      }
    }
  
  .send-arrow-position.enabled{                           
    cursor: pointer;
    border:none
  }
  .send-arrow-position.disabled{                                     
    border:none;
    background:none !important;
    cursor: default;
  }
   .textarea {
      width: 100%;
      height: 150px;
      padding: 12px 20px;
      box-sizing: border-box;
      border: 2px solid #ccc;
      border-radius: 4px;
      background-color: #f8f8f8;
      font-size: 16px;
      resize: none;
      background-color: yellow;
    }
  }
  
.container-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
  
.fixed-btn{
  position: fixed;
  bottom:20px;
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 40%;
  width: 6%;
  height: 10%;
  align-items: center;
}
.carousel-control-next{
  right: 3.5%;
}
.carousel-control-prev {
  left: 3.5%;
}