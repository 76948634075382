@keyframes dl {
    0% {
      box-shadow: 12px 0 rgba(4, 30, 58, 0.2), -12px 0 rgba(109, 111, 115, 0.75);
      background:rgba(4, 30, 58, 0.2);
    }
    33% {
      box-shadow: 12px 0 rgba(4, 30, 58, 0.2), -12px 0 rgba(109, 111, 115, 0.75);
      background: rgba(109, 111, 115, 0.75);
    }
    66% {
      box-shadow: 12px 0 rgba(109, 111, 115, 0.75), -12px 0 rgba(4, 30, 58, 0.2);
      background: rgba(109, 111, 115, 0.75);
    }
    100% {
      box-shadow: 12px 0 rgba(109, 111, 115, 0.75), -12px 0 rgba(4, 30, 58, 0.2);
      background: rgba(4, 30, 58, 0.2);
    }
  }

  //Chat bubble animation
.chatbubbleAnimate {
    position: relative;
    animation-name: chat-msg;
    animation-duration: 4s;
    }
  @keyframes chat-msg {
    0% {
      top: 800px;
    }
    30% {
      top: 320px;
    }
    100% {
      top: 0px;
    }
  } 
  .fadeOutPromts {
    animation: fadeOutPromts 100ms ease-in;
    }
  
  @keyframes fadeOutPromts {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
   
    90% {
      opacity: 0;
    }
   
    100% {
      opacity: 1;
    }
  }