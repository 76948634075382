.product-header-title {                            
  font-size: 28px;
  line-height: 34px;
  font-family: leJeuneDeck; 
  // margin-top: 4px;
  color: #041E3A;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize; 
    @media screen and (max-width: 959px){
      font-size: 20px;
      line-height: 30px;
      margin-top: 2px;
    }
    @media screen and (min-width: 960px){
      max-width: 600px;
    }
  }
  .borders-dotted {                                       
    border-bottom: 1px dashed #777;
    visibility: visible;
  }
  .branding-img{
    margin-right: 72px;
    @media screen and (min-width: 1200px) and (max-width: 1399px)  {
      margin-right: 25px;
    }
    @media screen and (min-width: 1400px) {
      margin-right: -35px; 
    }
  }
  
  .m-vLine {
    visibility: hidden;
    // border-left: 1px dashed #777;
    border-left: 1px dashed;
    border-image-source: url(../../../public/static/assets/vertical_dash.png);
    border-image-repeat: repeat;
    border-image-slice: 5;
  }
  
  .item-count {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  
  .img-res-multi {
    width: 160px;
    height: 224px;
  }
  
  .multi-img-gap {
    gap: 3px;
  }
  
  .product-detail {                                      
    // font-size: 18px;
    // line-height: 32px;
    color: #041E3A;
    font-style: normal;
    // font-family: leJeuneDeck;
    @media screen and (max-width: 959px) {
      // font-size: 14px;
      // line-height: 24px;
    }
  }
  @media screen and (max-width: 576px) {
  .img-res-multi {
      width: 115px;
      height: 161px;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 844px) {
    .multi-img-gap {
      gap: 1px;
    }
  
    .withoutMediaTextAlign {
      margin-left: 15px;
    }
  }
  
  .mcat-product-count {
    font-family: Le Jeune Deck;
      margin-bottom: 13px;
      margin-top: -4px;
      font-size: 80px;
      font-style: normal;
      line-height: 80px;
      color: var(--polo-primary, #041e3a);
    @media screen and (max-width: 959px) {
      font-family: leJeuneDeck;
      color: var(--polo-primary, #041e3a);
      font-size: 75px;
      font-style: normal;
      line-height: 85px;
    }
    @media screen and (min-width: 768px) and (max-width: 959px) {
      margin-top: -15px;
    }
  }
  .container-margin .multi-container {
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .main-multi-section {
    .para-main {
      font-family: leJeuneDeck;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      color: $bs-head-color;
    }
    .heading-main {
      font-family: "Lato", sans-serif;
      color: var(--bs-para-color);
    }
    @media screen and (max-width: 959px) {
      .para-main {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  
  .multiproductdetails {
    .container-margin {
      padding-top: 30px;
    }
    .product-detail {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 24px;
      color: var(--polo-primary, #041e3a);
    }
    .borders-dotted {
      border-bottom: 1px dashed #777;
      visibility: visible;
      margin-left: 45px;
      margin-bottom: 40px;
    }
    .multi-cat-margin {
      margin-left: 45px;
      width: 94%;
      margin-top: -1.4rem;
    }
    .details-div {
      margin-left: 4rem;
      margin-top: 15px;
    }
    @media screen and (max-width: 576px) {
      .product-count {
        margin-top: 0px;
      }
      .product-header-title {
        font-size: 20px;
        line-height: 30px;
      }
      .borders-dotted {
        visibility: hidden;
      }
      .product-detail {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      // .hrdiv {
      //   display: none;
      // }
      .multi-cat-margin {
        margin-left: 0px;
        margin-top: 0px;
        width: 100%;
      }
      .details-div {
        margin-left: 0rem;
      }
    }
    @media screen and (min-width: 579px) and (max-width: 767px) {
      .product-detail {
        font-size: 16px;
        line-height: 24px;
      }
      .product-header-title {
        font-size: 22px;
        line-height: 30px;
      }
      .borders-dotted {
        // border-bottom: 1px dashed #777;
        visibility: visible;
        margin-bottom: 25px;
        margin-left: 0px;
      }
      .multi-cat-margin {
        margin-top: -1.4rem;
        margin-left: 0px;
        width: 100%;
      }
      .details-div {
        margin-left: 0rem;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .main-section .para-main {
        font-size: 22px;
        line-height: 34px;
      }
      .product-header-title {
        font-size: 23px;
        line-height: 30px;
      }
      .borders-dotted {
        margin-left: 0px;
      }
      .multi-cat-margin {
        margin-top: -1.4rem;
        margin-left: 0px;
        width: 100%;
      }
      .details-div {
        margin-left: 0rem;
      }
    }
    @media (min-width: 992px) {
      .img-container {
        overflow-x: auto;
      }
      .img-container::-webkit-scrollbar {
        display: none;
      }
      .multi-cat-margin {
        margin-left: 15px;
        width: 94%;
        margin-top: -1.4rem;
      }
      .details-div {
        margin-left: 3rem;
      }
    }
  }
  
  //   ***********************    Media Query     *********************
  
  @media screen and (max-width: 576px) {
  
    .img-container {
      overflow-x: auto;
    }
    .img-container::-webkit-scrollbar {
      display: none;
    }
  
    .m-vLine {
      visibility: visible;
      height: auto;
      opacity: 75%;
    }
  
  
    .borders-dotted {
      border: 1px dashed #777;
      visibility: hidden;
    }
  
    
    .borders-dotted {
      border: 1px dashed #777;
      visibility: hidden;
    }
  
    .img-res {
      width: 250px;
    }
  
    .item-count {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .container-margin {
      padding-top: 20px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .i-pad {
      margin-left: -0.7rem !important;
    }
  }
  @media screen and (min-width: 1400px) {
    .i-pad {
      margin-left: -3.1rem;
    }
  }
  
  @media screen and (max-width: 667px) and (min-width: 375px) {
    .img-container {
      overflow-x: auto;
    }
    .img-container::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media screen and (min-width: 390px) and (max-width: 844px) {
    .img-container {
      overflow-x: auto;
    }
    .img-container::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .img-container {
      overflow-x: auto;
    }
    .img-container::-webkit-scrollbar {
      display: none;
    }
  }
  .multi-cat-border{
    margin-bottom: 40px;
    margin-top: 40px;
    @media screen and (max-width:959px){
      margin-bottom: 30px;
      margin-top: 30px; 
    }
  }
  .dashed-line{
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media screen and  (min-width: 577px) and (max-width: 767px){
    .vertical-dash {
      margin-left: 3% !important;
    }
  }
  @media screen and (max-width: 767px){
    .vertical-dash {
      border-left: 1px dashed;
      border-image-source: url(../../../public/static/assets/vertical_dash.png);
      border-image-repeat: repeat;
      border-image-slice: 5;
      margin-left: 7%;
      margin-bottom: 30px;
    }
    .margin{
      margin-left: 7%;
      margin-top: -22%;
    }
    .borders-dotted {
      border: 1px dashed #777;
      display: none;
    }
    .mcat-img{
      width: 115px !important;
      height: 161px !important;
    }
  }
  .margin{
    @media screen and (max-width: 420px){
      margin-top: -24% !important;
    }
    @media screen and (min-width: 520px)and (max-width: 670px){ 
     margin-top: -18% !important;
    }
    @media screen and (min-width: 671px)and (max-width: 767px){
     margin-top: -14% !important;  
    }
    @media screen and (max-width: 576px){
      margin-left:8% !important;
    }
  }