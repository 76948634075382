@keyframes userOnboardBackground{
    from {
      transform:scale(1);
      opacity:0.5;
      // background-color: white;    
    }
    to {
      transform: scale(1.02);
      opacity: 1;
    }
  }
  
  .userOnboardBackgroundMain{
    animation: userOnboardBackground 3s ease-in forwards;
  }
  
  
  // @keyframes backOutImg{
  //   from {
  //     transform:translate(0px,-5px) scale(1.02);
  //     opacity:1;     
  //   }
  //   to {
  //     transform:translate(-15,-5px) scale(1);
  //     opacity: 0;
  //     background-color: white;  
  //   }
  // }
  
  // .backgroundOut{
  //   animation: backOutImg 1s ease-out forwards;
  // }
  
  .animateSearch{
    animation: movefromBottomSearch 1.5s forwards ease-in;
   }
  
    @keyframes movefromBottomSearch {
    0% {
      opacity: 0;
      transform: translateY(5rem);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }  
  }
  
  .animateSearchOut{
    animation: moveToBottomSearch 1s forwards ease-in;
   }
  
  @keyframes moveToBottomSearch {
    0% {
      opacity: 1;
      transform: translate(0);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 0;
      transform: translateY(5rem);
    }  
  }
  .carousel-in{
    animation: carouselIn 1.5s forwards ease-in;
  }
  @keyframes carouselIn {
    0% {
      opacity: 0;
      transform: translateY(5rem);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }  
  }
  .carousel-out{
    animation: carouselOut 1s forwards ease-in;
  }
  @keyframes carouselOut {
    0% {
      opacity: 1;
      transform: translate(0);
    }
    80% {
      opacity: 0.5;    
    }
    100% {
      opacity: 0;
      transform: translateY(5rem);
    }  
  }
  .reveal-container{          //not used
    overflow: hidden;
  }
  
  @keyframes imgTypingEffect {         //not used
    from{
      clip-path: polygon(0 0,0 100%,0% 100%,0% 0%);
    }
    to{
      clip-path: polygon(0 0,100 0,100% 100%,0% 100%);
    }
  }
  
  .onboard-loader{
    width: 8px;
    height: 8px;
    position:absolute;
    top: 25px;
    right: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: loader 1s infinite linear alternate;
    @media screen and (max-width: 959px) {
      top: 48px;
    }
  }
  
  @keyframes loader {
    0% {
      box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
      background:#6D6F73;
    }
    33% {
      box-shadow: 15px 0 #6D6F73, -15px 0 #0002;
      background: #0002;
    }
    66% {
      box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
      background: #0002;
    }
    100% {
      box-shadow: 15px 0 #0002, -15px 0 #6D6F73;
      background: #6D6F73;
    }
  } 
  // .prompt-content-fadein{
  //   animation: prompt-content-fadein;
  //   animation-fill-mode: forwards;
  //   animation-delay: 0.4s;
  //   animation-duration: 0.4s;
  //   opacity:0;
  //  }
  // @keyframes prompt-content-fadein {
  //   0%   { opacity:0; }
  //   100% { opacity:1; }
  // }

  .prompt-content-fadein{
    animation: prompt-content-fadein 8s ease-in-out forwards;
    opacity:0;
   }
  @keyframes prompt-content-fadein {
    0%   { opacity:0; }
    30%  { opacity:1; }
    100% { opacity:1; }
  }
  
  .prompt-fadein{
    animation: prompt-fadein 0.8s forwards ease-in;
    
  }
  @keyframes prompt-fadein {
    0% {
      transform: translateY(295px);
      opacity: 0;
        }
    100% {
      transform: translate(0);
      opacity: 1;
    }  
  }
  .prompt-fadeout{
    animation: prompt-fadeout 0.8s forwards ease-out;
    
    }
  @keyframes prompt-fadeout {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    100% {
      transform: translateY(295px);
      opacity: 0;
      display: none;
    }  
  }
  .help-btn-fadein{
    animation: help-btn-fadein;
    animation-fill-mode: forwards;
    animation-delay: 2.56s;
    animation-duration: 0.44s;
    opacity:0;
   }
  @keyframes help-btn-fadein {
    0%   { opacity:0; }
    100% { opacity:1; }
  }

  .help-btn-easein{
    animation: help-btn-easein;
    animation-fill-mode: forwards;
    animation-delay:0.3s;
    animation-duration: 0.5s;
    opacity:0%;
  }
  @keyframes help-btn-easein {
    0%   { opacity:0%; }
    100% { opacity:100%; }
  }
  .help-btn-easeout{
    animation: help-btn-easeout;
    animation-fill-mode: forwards;
    animation-duration: 0.33s;
    opacity:100%;
  }
  @keyframes help-btn-easeout {
    0%   { opacity:100%; }
    100% { opacity:0%; }
  }