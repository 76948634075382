.TextResponse {
    .chat_msg1 {
      font-size: 16px;
      line-height: 30px;
      color: #041E3A;
      border-radius: 10px;
      padding: 12px;
      font-family: foundersGroteskTextRegular;
      font-style: normal;
      font-weight: 400;
      @media screen and (max-width: 959px){
        font-size: 13px !important;
        line-height: 22px !important;
      }
      // .chat1 {
      //   font-family: leJeuneDeck;
      //   font-size: 28px;
      //   line-height: 40px;
      //   margin-bottom: 15px;
      //   color: var(--polo-primary, #041e3a);
      // }
      .chat2 {
        font-size: 16px;
        line-height: 30px;
        font-family: foundersGroteskTextRegular;
        font-style: normal;
        font-weight: 400;
        color: var(--polo-primary, #041e3a);
      }
      @media screen and (max-width: 959px) {
        // .chat1 {
        //   font-family: leJeuneDeck;
        //   font-size: 18px;
        //   line-height: 24px;
        //   margin-bottom: 10px;
        // }
        .chat2 {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
    .chat_msg2 {
      width: 22%;
      background: #ffffff;
      // font-size: 14px;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 12px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
    }
    .col-2 {
      font-size: 10px;
    }
    @media screen and (max-width: 576px) {
      .chat_msg1 {
        width: 100%;
        // font-size: 14px;
        border-radius: 10px;
        padding: 12px;
      }
      .chat_msg2 {
        width: 100%;
        background: #ffffff;
        // font-size: 14px;
        margin-bottom: 10px;
        border-radius: 10px;
        padding: 12px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.0509803922);
      }
    }
    .text-res-divider{
      margin-top: 40px;
      margin-bottom: 40px;
      @media screen and (max-width: 959px) {
        margin-top: 30px;
        margin-bottom: 30px; 
      }
    }
    .text-res-conclusion{
      font-size: 18px;
      line-height: 32px;
      color: #041E3A;
      font-style: normal;
      font-family: leJeuneDeck;
      @media screen and (max-width: 959px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
// numbered listing in text response
  //   ol li p strong{
  //     font-size: 20px;
  //     line-height: 30px;
  //     font-family: leJeuneDeck; 
  //   }
  //   ol li::marker{
  //     font-size: 20px;
  //     line-height: 30px;
  //     font-family: leJeuneDeck; 
  //   }
  //   ul li::marker{
  //     font-size: 16px;
  //     line-height: 30px;
  //     font-family: foundersGroteskTextRegular;
  //     font-style: normal;
  //     font-weight: 400;
  //     @media screen and (max-width: 959px) {
  //       font-size: 13px;
  //       line-height: 22px;
  //     }
  //   }
   }