@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200&family=Lato:wght@100;300;400;700;900&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200&family=Lato:wght@100;300;400;700;900&family=PT+Serif:ital@1&family=Playfair+Display:ital,wght@0,400;0,500;1,400&family=Sometype+Mono&family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");

@font-face {
    font-family: leJeuneDeck;
    src: url(../../../public/static/assets/fonts/LeJeuneDeck-Regular.otf);
    font-weight: 400;
}
  
@font-face {
    font-family: foundersGroteskMonoMedium;
    src: url(../../../public/static/assets/fonts/FoundersGroteskMono-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: foundersGroteskMonoRegular;
    src: url(../../../public/static/assets/fonts/FoundersGroteskMono-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: foundersGroteskTextMedium;
    src: url(../../../public/static/assets/fonts/FoundersGroteskText-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: foundersGroteskTextRegular;
    src: url(../../../public/static/assets/fonts/FoundersGroteskText-Regular.otf);
    font-weight: 400;
}
@font-face {
    font-family: foundersGroteskTextBold;
    src: url(../../../public/static/assets/fonts/FoundersGroteskText-bold.otf);
    font-weight: 700;
}

.leJeuneDeck {
    font-family: leJeuneDeck;
}

.foundersGroteskMonoRegular {
    font-family: foundersGroteskMonoRegular;
}

.foundersGroteskMonoMedium {
    font-family: foundersGroteskMonoMedium;
}

.foundersGroteskTextRegular {
    font-family: foundersGroteskTextRegular;
}

.foundersGroteskTextMedium {
    font-family: foundersGroteskTextMedium;
}
.foundersGroteskTextBold {
    font-family: foundersGroteskTextBold;
}

.font_12 {
    font-size: 12px !important;
}
.font_14 {
    font-size: 14px !important;
}
.font_16 {
    font-size: 16px !important;
}
.font_18 {
    font-size: 18px !important;
}
.font_24 {
    font-size: 24px !important;
}